import { useState } from 'react'
import { Form, Button, Checkbox, message, Row, Col, Typography, notification, Flex } from 'antd'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import ContentCard from '../../components/layouts/ContentCard'
import { useNavigate } from 'react-router-dom'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterSubscription() {
  const [isLoading, setIsLoading] = useState(false)
  const userNewsletter = useUserNewsletter()
  const { updateNewsletterStatus } = userNewsletter
  const navigate = useNavigate()

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      await updateNewsletterStatus('active', 'main')
      notification.open({
        icon: false,
        description: (
          <Flex vertical justify="center" align="center" gap={12}>
            <img src={'/icons/ok.svg'} alt="success" width={44} />
            <Typography.Title level={1} style={{ margin: 0 }}>
              {'קיבלנו!'}
            </Typography.Title>
            <Typography.Title level={4} style={{ margin: 0, textAlign: 'center' }}>
              {'נרשמת בהצלחה לניוזלטר של ערוץ הספורט'}
            </Typography.Title>
          </Flex>
        ),
      })

      navigate('/')
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const validator = (_, value) => {
    if (value) {
      return Promise.resolve()
    }

    return Promise.reject(new Error('יש לאשר את המדיניות הפרטיות ותנאי השימוש.'))
  }

  return (
    <Row justify="center" className="bg bg4">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard loading={userNewsletter.isLoading}>
          <Row justify="center">
            <Col flex="600px">
              <Typography.Title level={3}>
                {'לחצו והירשמו לניוזלטר של ערוץ הספורט כדי לקבל את מיטב העדכונים והכתבות ישירות למייל'}
              </Typography.Title>
              <Form onFinish={onSubmit}>
                <Form.Item name="agree" valuePropName="checked" rules={[{ validator }]}>
                  <Flex gap={8} align="baseline">
                    <Checkbox
                      data-gtm-event_name="element_click"
                      data-gtm-description="approval_newsletter"
                      data-gtm-purpose="approval"
                      data-gtm-type="checkbox"
                      data-gtm-flow="main"
                    ></Checkbox>
                    <Typography.Text style={{ textAlign: 'right' }}>
                      {
                        ' אני מאשר קבלת חומרים שיווקיים, עדכונים ומידע, של ערוץ הספורט ושל שותפיו העסקיים, באמצעות שיחות, מסרונים, דוא"ל או כל תקשורת דיגיטלית אחרת. תוכל בכל עת לבטל הסכמתך באמצעות פנייה בדוא"ל ל '
                      }
                      <a style={{ textDecoration: 'underline', color: 'inherit' }} href="mailto:support@rge.co.il">
                        {'support@rge.co.il'}
                      </a>
                      {` או באמצעות דרכי ההסרה שיפורטו בהודעה. `}
                    </Typography.Text>
                  </Flex>
                </Form.Item>
                <Button shape="round" htmlType="submit" type="primary" loading={isLoading}>
                  {'להרשמה'}
                </Button>
              </Form>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
