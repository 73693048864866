import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider, notification, message } from 'antd'
import heIL from 'antd/locale/he_IL'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/he'
import { UserProvider } from './hooks/useUser'
import theme from './configs/theme.json'
import Router from './router'
import ScrollToTop from './components/layouts/ScrollToTop'

dayjs.locale('he')
dayjs.extend(relativeTime)
dayjs.extend(utc)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
})

function App() {
  notification.config({ rtl: true })
  message.config({ rtl: true })

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={theme} direction="rtl" locale={heIL} componentSize="large">
          <UserProvider>
            <Router />
          </UserProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
