import { useEffect, useMemo, useState } from 'react'
import { StatusCodes } from 'http-status-codes'
import { Form, Button, Input, Row, Col, Alert, Typography, Flex } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useUser } from '../../hooks/useUser'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'

export default function Verification() {
  const { user, verify, logOut, isFetched } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const [attemps, setAttemps] = useState(3)
  const [error, setError] = useState()
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const label = sessionStorage.getItem('referrerMessage') || 'שלחנו קוד חד פעמי למייל שהוזן'

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem('referrerMessage')) {
        sessionStorage.removeItem('referrerMessage')
      }
    }
  }, [])

  useEffect(() => {
    if (!isFetched) return

    if (!isEmpty(user)) {
      if (user.isVerified) {
        searchParams.get('redirect')
          ? navigate(searchParams.get('redirect'))
          : navigate('/', {
              state: {
                showPopup: true,
              },
            })
      }
    } else {
      navigate('/login')
    }
  }, [user, isFetched, navigate, searchParams])

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)

      await verify(values, searchParams.get('flow') || 'sign_up')
    } catch (error) {
      const { response = {} } = error
      const { data, status, headers } = response

      setError({ data, status, headers })
      setAttemps((prevState) => (prevState -= 1))
    } finally {
      setIsLoading(false)
    }
  }

  const onValuesChange = () => {
    if (error) {
      setError()
    }
  }

  const onLogout = () => {
    logOut()
    navigate(-1)
  }

  const errorMessage = useMemo(() => {
    if (!error) return null

    const { status, data } = error
    let message = data

    switch (status) {
      case StatusCodes.TOO_MANY_REQUESTS:
        message = 'בוצעו יותר משלושה נסיונות כושלים, יש לנסות שוב מאוחר יותר'
        break
      default:
        message = `קוד האימות שהוזן שגוי, נותרו עוד ${attemps + 1} ניסיונות`
    }

    return message
  }, [error, attemps])

  return (
    <Row justify="center" className="bg bg2">
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton onClick={onLogout} />
            </Flex>
          }
        >
          <Row justify="center">
            <Col flex="320px">
              <Flex vertical gap={24}>
                <Typography.Text strong>{label}</Typography.Text>

                <Form onFinish={onSubmit} onValuesChange={onValuesChange} layout="vertical">
                  <Form.Item
                    label={<Typography.Text strong>{'מה הקוד שקיבלת?'}</Typography.Text>}
                    name="verificationCode"
                    rules={[{ required: true, message: 'יש להזין את הקוד אימות' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Row gutter={[12, 12]} justify="space-between" align="bottom">
                    <Col xs={24}>
                      <Button block shape="round" size="large" type="primary" htmlType="submit" loading={isLoading}>
                        {'המשך'}
                      </Button>
                    </Col>
                    {errorMessage && (
                      <Col xs={24}>
                        <Alert type="error" message={errorMessage} />
                      </Col>
                    )}
                  </Row>
                </Form>
              </Flex>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
