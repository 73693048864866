import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Typography, Button, message, Flex } from 'antd'
import { isEmpty } from 'lodash'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import BackButton from '../../components/elements/BackButton'
import { ReactComponent as PauseWeek } from '../../assets/icons/subscription-pause-week.svg'
import { ReactComponent as PauseMonth } from '../../assets/icons/subscription-pause-month.svg'
import { ReactComponent as Pause3Months } from '../../assets/icons/subscription-pause-3-months.svg'
import { ReactComponent as Pause } from '../../assets/icons/subscription-pause.svg'
import dayjs from 'dayjs'
import ContentCard from '../../components/layouts/ContentCard'
import { track } from '../../utils/analytics'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

const options = [
  {
    Icon: PauseWeek,
    label: 'הפסקה לשבוע',
    period: [7, 'days'],
  },
  {
    Icon: PauseMonth,
    label: 'הפסקה לחודש',
    period: [1, 'month'],
  },
  {
    Icon: Pause3Months,
    label: 'הפסקה לשלושה חודשים',
    period: [3, 'months'],
  },
  {
    Icon: Pause,
    label: 'לא מעוניין לקבל יותר את הניוזלטר',
    period: [],
  },
]

export default function NewsletterUnsubscriptionOptions() {
  const [isLoading, setIsLoading] = useState(false)
  const { updateNewsletterStatus, updateNewsletterFreez } = useUserNewsletter()
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState()

  const onSubmit = async () => {
    const { period, label } = options.find((option) => option.label === selectedOption)

    const isUnsubscribe = isEmpty(period)

    try {
      setIsLoading(true)

      if (isUnsubscribe) {
        await updateNewsletterStatus('unsubscribed')
      } else {
        const [num, duration] = period
        const optOutEndDate = dayjs.utc().add(num, duration).format()

        await updateNewsletterFreez({ optOutEndDate })
      }

      track({
        event_name: 'form_submit',
        form_name: 'newsletter_unsubscription_options',
        purpose: 'pause_time_update',
        flow: 'unsubscribe_flow',
        description: label,
      })

      navigate('/newsletter/unsubscription/survey', { state: { period } })
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row justify="center" className="bg bg7">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row justify="center" gutter={[24, 24]}>
            <Col flex="600px">
              <Typography.Title style={{ fontWeight: 400 }} level={3}>
                {'לא עניין של תדירות או תוכן?'}
                <br />
                {`אין בעיה, הנה מה שאפשר לעשות:`}
              </Typography.Title>
            </Col>
            <Col flex="350px">
              <Row gutter={[24, 24]}>
                {options.map((option) => {
                  const { Icon, label } = option

                  return (
                    <Col xs={12} key={label}>
                      <Flex
                        vertical
                        gap={12}
                        align="center"
                        className={`icon-button-svg ${selectedOption === label ? 'icon-button-svg-active' : ''}`}
                        onClick={() => setSelectedOption(label)}
                        data-gtm-event_name="element_click"
                        data-gtm-description={label}
                        data-gtm-flow="unsubscribe"
                        data-gtm-purpose="pause_time_update"
                        data-gtm-type="selection"
                      >
                        <Icon />
                        <span>{label}</span>
                      </Flex>
                    </Col>
                  )
                })}
              </Row>
            </Col>
            <Col xs={24}>
              <br />
              <Button shape="round" loading={isLoading} type="primary" disabled={!selectedOption} onClick={onSubmit}>
                {'המשך'}
              </Button>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
