import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Row, Col, Upload, Card, Image, theme, message } from 'antd'
import { useState } from 'react'

export const UploadImage = ({ uploadToCloudinary, iconUrl = '' }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { token } = theme.useToken()

  const uploadChange = async ({ file }) => {
    try {
      setIsLoading(true)
      await uploadToCloudinary({ file })
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
        height: '100%',
        cursor: 'pointer',
      }}
      type="button"
    >
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        העלאת לוגו
      </div>
    </button>
  )

  return (
    <Col xs={24}>
      <Row gutter={[12, 12]}>
        {iconUrl !== '' && (
          <Col key={iconUrl} xs={12} lg={8} xl={6}>
            <Card
              style={{ border: `2px solid ${token.colorPrimary}` }}
              styles={{
                body: {
                  padding: 8,
                },
              }}
            >
              <Image preview={false} src={iconUrl} />
            </Card>
          </Col>
        )}
        <Col xs={12} lg={8} xl={6}>
          <Card
            hoverable
            style={{ border: '1px solid #d9d9d9', height: '100%' }}
            styles={{
              body: {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <Upload
              listType="picture"
              accept="image/jpeg, image/png, image/svg+xml"
              multiple={false}
              maxCount={1}
              showUploadList={false}
              beforeUpload={() => false}
              onChange={uploadChange}
            >
              {uploadButton}
            </Upload>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
