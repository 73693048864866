import React from 'react'
import { useResponsive } from '../../../hooks/useResponsive'
import { Card, Col, Row } from 'antd'

export const PizzaHutNewsletterWrapper = ({ children }) => {
  const { isMobileView } = useResponsive()
  return isMobileView ? (
    <Row justify="center" className="bg-pizzahut-cover-mobile">
      <Col xs={48} align="middle" className="bg-pizza-mobile">
        {children}
      </Col>
    </Row>
  ) : (
    <Row className="bg-pizzahut-cover">
      <Row justify="center" className="bg-pizza">
        <Col xs={24} align="middle" flex="866px">
          <Card
            className="content-card"
            style={{
              backgroundImage: `url(/images/backgrounds/bg-pizzahut-text.png)`,
              backgroundSize: 'cover',
              border: 'none',
            }}
          >
            {children}
          </Card>
        </Col>
      </Row>
    </Row>
  )
}
