import axios from 'axios'
import { useQuery } from 'react-query'
import { useUser } from './useUser'

const endpoint = '/api/user/preferred-teams'

export function useUserPreferredTeams() {
  const { user } = useUser()
  const { email, isVerified } = user

  const {
    isLoading,
    data: response = {},
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['preferredTeams', email],
    queryFn: () => axios.get(endpoint),
    enabled: isVerified,
  })

  const { data = [] } = response
  return {
    data,
    isLoading,
    isRefetching,
    refetch,
  }
}
