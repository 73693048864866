import React, { useRef, useEffect, useCallback } from 'react'
import Article from './Article'
import ArticlesHeader from './ArticlesHeader'
import Loader from '../layouts/Loader'
import { Row, Col, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { useResponsive } from '../../hooks/useResponsive'

const Articles = ({ articles, fetchNextPage, hasNextPage, isLoading, titleContent, useExclusive = false }) => {
  const observer = useRef()
  const { isMobileView } = useResponsive()
  const lastArticleRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage()
          }
        },
        { rootMargin: '100px' },
      )

      if (node) observer.current.observe(node)
    },
    [isLoading, hasNextPage, fetchNextPage],
  )

  useEffect(() => {
    return () => {
      if (observer.current && lastArticleRef.current) {
        observer.current.unobserve(lastArticleRef.current)
      }
    }
  }, [])

  if (isLoading) return <Loader />

  const MyTeamsPage = isEmpty(articles) && isEmpty(titleContent) && useExclusive

  return (
    <Row justify={'center'} gutter={[10, 10]} style={{ margin: 0 }}>
      {!isEmpty(titleContent) && <ArticlesHeader titleContent={titleContent} />}
      {MyTeamsPage && <Typography.Text strong>ברגע שיעלו כתבות על הקבוצה שלך תוכל למצוא אותן כאן</Typography.Text>}
      {articles.map((article) => (
        <Col key={article?.url} flex={isMobileView && '558px'}>
          <Article
            key={article?.url}
            imageUrl={article?.image}
            date={article?.publishDate}
            title={article?.title}
            description={article?.subTitle}
            authorName={article?.author}
            url={article?.url}
            teamName={article?.teamName}
            exclusiveContent={article?.exclusiveContent}
            useExclusive={useExclusive}
          />
        </Col>
      ))}
      <Col xs={24}>{hasNextPage && <div ref={lastArticleRef} style={{ height: 20, background: 'transparent' }} />}</Col>
    </Row>
  )
}

export default Articles
