import React from 'react'
import RequiredAuthenticationRoute from './RequiredAuthenticationRoute'
import RequiredVerificationRoute from './RequiredVerificationRoute'
import RequiredRegistrationRoute from './RequiredRegistrationRoute'
 


export default function PrivateRoute({ children }) {
  return (
    <RequiredAuthenticationRoute>
        <RequiredVerificationRoute>
            <RequiredRegistrationRoute>
                {children}
            </RequiredRegistrationRoute>
        </RequiredVerificationRoute>
    </RequiredAuthenticationRoute>
  )
}
