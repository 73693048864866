import { useState, createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { track } from '../utils/analytics'

const UserContext = createContext()

export function useUser() {
  return useContext(UserContext)
}

export function UserProvider({ children }) {
  const [user, setUser] = useState()
  const [isRegistered, setIsRegistered] = useState(false)
  const [error, setError] = useState()
  const [token, setToken] = useState('')
  const isVerified = Boolean(user?.isVerified)

  const { isLoading, isFetched } = useQuery({
    queryKey: ['auth', token],
    queryFn: () => axios.get('/api/auth/' + token),
    onSuccess: ({ data }) => setUser(data),
    onError: setError,
  })

  const login = async (payload, flow = 'sign_up') => {
    const { data, status } = await axios.post('/api/auth/login/otp/email', payload)
    const isRegistered = status === 201

    track({
      event_name: isRegistered ? 'sign_up_begin' : 'login_begin',
      method: 'email',
      email: payload.email.trim(),
      flow,
      purpose: 'input_email',
    })

    setUser(data)
    setIsRegistered(isRegistered)

    return data
  }

  const logOut = async () => {
    try {
      await axios.post('/api/auth/logout')
    } catch (error) {
    } finally {
      setUser()
    }
  }

  const verify = async (payload, flow = 'sign_up') => {
    await axios.post('/api/auth/verify/otp', payload)
    setUser((prevState) => ({ ...prevState, isVerified: true }))

    track({
      event_name: isRegistered ? 'sign_up' : 'login',
      method: 'email',
      email: user.email,
      flow,
      purpose: 'input_otp',
    })
  }

  const updateProfile = async (payload) => {
    const { data } = await axios.put('/api/user/profile', payload)
    setUser((prevState) => ({ ...prevState, ...data }))
  }

  const sendPhoneNumberVerification = async (phoneNumber) => {
    await axios.post('/api/user/profile/phone/verification/send', { phoneNumber })
    if (phoneNumber !== user.phoneNumber) {
      setUser((prevState) => ({ ...prevState, phoneNumber }))
    }
  }

  const verifyPhoneNumber = async (verificationCode) => {
    await axios.post('/api/user/profile/phone/verification', { verificationCode })
    setUser((prevState) => ({ ...prevState, isPhoneNumberVerified: true }))
  }

  const clearError = () => {
    setError()
  }

  const value = {
    user,
    isVerified,
    error,
    isLoading,
    isFetched,
    isRegistered,
    clearError,
    login,
    logOut,
    verify,
    setToken,
    updateProfile,
    sendPhoneNumberVerification,
    verifyPhoneNumber,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
