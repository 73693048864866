import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useUser } from './useUser'

async function getPreferredSports() {
    const { data } = await axios.get('/api/user/preferred-sports')
    return data
  }
  
  async function updatePreferredSports(preferredSports = []) {
    const { data } = await axios.put('/api/user/preferred-sports', { preferredSports })
    return data
  }
  
  export function useGetUserPreferredSports(){
    const { user } = useUser()
    const { email, isVerified } = user
  
    return useQuery({
      queryKey: ['preferredSports', email],
      queryFn: getPreferredSports,
      enabled: isVerified,
    })
  }
  
  export function useUpdateUserPreferredSports(){
    const queryClient = useQueryClient()
    const { user } = useUser()
    const { email } = user
  
    const { mutateAsync, isLoading } = useMutation(updatePreferredSports, {
      onSuccess: () => {
        queryClient.invalidateQueries(['preferredSports', email])
      }
    })
  
    return {
      updatePreferredSports: mutateAsync,
      isLoading
    }
  }