import { Row, Col, Flex } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'
import { useMemo } from 'react'
import { useTeams } from '../../hooks/useTeams'
import { createAllTeamOptions } from '../teams/MyTeams.utils'
import RegisterFavoriteTeamsForm from '../teams/components/RegisterFavoriteTeamsForm'
import { useNavigate } from 'react-router-dom'

export default function PreferredTeams() {
  const { data } = useTeams()
  const navigate = useNavigate()

  const sportCategories = useMemo(
    () =>
      data.reduce(
        (acc, team) => {
          return createAllTeamOptions(acc, team)
        },
        {
          israeliFootball: [],
          worldFootball: [],
          basketball: [],
          nba: [],
        },
      ),
    [data],
  )
  const handleClick = () => {
    navigate('/')
  }

  return (
    <Row className="bg bg5" justify="center">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton onClick={handleClick} />
            </Flex>
          }
        >
          <RegisterFavoriteTeamsForm sportCategoriesOptions={sportCategories} navigateTo={'/'} />
        </ContentCard>
      </Col>
    </Row>
  )
}
