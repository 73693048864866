import { Avatar, Flex } from 'antd'
import { isEmpty } from 'lodash'
import React from 'react'

const desktopStyle = { width: '300px', paddingRight: '34px', paddingBottom: '20px' }
const TeamsIcons = ({ teamsData, isMobileView }) => {
  if (isEmpty(teamsData)) return null
  return (
    <Flex gap={20} style={!isMobileView && desktopStyle}>
      {teamsData.map((team) => (
        <Avatar key={team.iconUrl} size={isMobileView ? 44 : 50} src={team.iconUrl} shape="square" />
      ))}
    </Flex>
  )
}

export default TeamsIcons
