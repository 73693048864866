import React, { useEffect, useMemo, useState } from 'react'
import { isEmpty, debounce } from 'lodash'
import { HolderOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Table,
  Row,
  Col,
  Input,
  Button,
  Modal,
  Form,
  message,
  Checkbox,
  Select,
  Typography,
  Tag,
  Space,
  Tooltip,
} from 'antd'
import TableActions from '../../components/elements/TableActions'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSportCategories } from '../../hooks/useSportCategories'

export default function SportCategories() {
  const [search, setSearch] = useState()
  const [isCategoryLoading, setCategoryLoading] = useState(false)
  const [isFormModalOpen, setFormModal] = useState(false)
  const [formValues, setFormValues] = useState()
  const [dataSource, setDataSource] = useState([])
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const { data = [], isLoading, createCategory, deleteCategory, updateCategory } = useSportCategories()

  useEffect(() => {
    if (isEmpty(data) || isLoading) return
    let newData = []
    if (!search) {
      newData = data
        .map((item) => (item.subCategories.length > 0 ? item : { ...item, subCategories: undefined }))
        .sort((a, b) => a.order - b.order)
    } else {
      newData = data
        .filter((item) => JSON.stringify(item).includes(search))
        .map((item) => (item.subCategories.length > 0 ? item : { ...item, subCategories: undefined }))
    }
    setDataSource(newData)
  }, [data, isLoading, search])

  const onSearchChange = debounce((event) => {
    const { value = '' } = event.target
    setSearch(value.trim())
  }, 200)

  const onFormModalClose = () => {
    setFormValues()
    setFormModal(false)
  }

  const onFormModalOpen = (values) => {
    if (values) setFormValues(values)
    setFormModal(true)
  }

  const onSubmit = async (values) => {
    try {
      setCategoryLoading(true)
      const action = isEmpty(formValues) || formValues.isNew ? createCategory : updateCategory

      await action(values)
      onFormModalClose()
    } catch (error) {
      message.error(error.message)
    } finally {
      setCategoryLoading(false)
    }
  }

  const ondeleteSportCategory = async ({ _id, name }) => {
    try {
      setCategoryLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את הקטגוריה "${name}"?`,
        okText: 'אישור',
        async onOk() {
          await deleteCategory(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setCategoryLoading(false)
    }
  }

  const parentCategoryOptions = useMemo(() => {
    if (!data) return []
    return data.filter((item) => item.subCategories).map((item) => ({ value: item._id, label: item.name }))
  }, [data])

  const onDragEnd = async ({ active, over }) => {
    const newData = Array.from(dataSource)
    const activeIndex = newData.findIndex((item) => item._id === active.id)
    const overIndex = newData.findIndex((item) => item._id === over.id)

    const newArray = newData
      .map((category) => {
        if (category.order === activeIndex) {
          return { ...category, order: activeIndex }
        }
        if (category.order === overIndex) {
          return { ...category, order: overIndex }
        }
        return category
      })
      .sort((a, b) => a.order - b.order)
    setDataSource(newArray)

    await Promise.all([
      updateCategory({ _id: active.id, order: over.data.current.sortable.index }),
      updateCategory({ _id: over.id, order: active.data.current.sortable.index }),
    ])
  }
  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record._id])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record._id))
    }
  }

  const collapseAllRows = () => {
    setExpandedRowKeys([])
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd} onDragStart={collapseAllRows}>
      <SortableContext items={dataSource.map((i) => i._id)} strategy={verticalListSortingStrategy}>
        <Table
          dataSource={dataSource}
          loading={isLoading}
          rowKey="_id"
          bordered
          rowHoverable={false}
          pagination={false}
          expandable={{
            childrenColumnName: 'subCategories',
            expandedRowKeys: expandedRowKeys,
            onExpand: handleExpand,
          }}
          components={{
            body: {
              row: TableRow,
            },
          }}
          columns={[
            {
              key: 'expandable',
              width: 70,
              align: 'center',
            },
            {
              align: 'center',
              key: 'sort',
              width: 70,
            },
            {
              align: 'center',
              title: 'נראה למשתמש?',
              width: 100,
              dataIndex: 'isVisible',
              render: (item) => <Checkbox checked={item} />,
            },
            {
              title: 'שם הקטגוריה',
              dataIndex: 'name',
            },
            {
              title: 'מספרי תיקייה',
              dataIndex: 'folderIds',
              render: (folderIds = []) => folderIds.map((folderId) => <Tag key={folderId}>{folderId}</Tag>),
            },
            {
              width: 80,
              align: 'left',
              render: (record) => {
                return (
                  <Space>
                    {!record.parentId && (
                      <Tooltip placement="top" title={'הוספת תת ענף ספורט'}>
                        <Button
                          size="small"
                          onClick={() => onFormModalOpen({ parentId: record._id, isNew: true })}
                          icon={<PlusOutlined />}
                        />
                      </Tooltip>
                    )}
                    <TableActions
                      onDeleteClick={() => ondeleteSportCategory(record)}
                      onUpdateClick={() => onFormModalOpen({ ...record, isNew: false })}
                    />
                  </Space>
                )
              },
            },
          ]}
          title={() => (
            <Row justify="space-between" align="middle">
              <Col>
                <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
              </Col>
              <Col>
                <Button type="primary" onClick={() => onFormModalOpen()}>
                  {'הוספת קטגוריה'}
                </Button>
                <Modal title={' '} open={isFormModalOpen} footer={null} destroyOnClose onCancel={onFormModalClose}>
                  <CategoryForm
                    onSubmit={onSubmit}
                    updateCategory={updateCategory}
                    parentCategoryOptions={parentCategoryOptions}
                    initialValues={formValues}
                    isLoading={isCategoryLoading}
                  />
                </Modal>
              </Col>
            </Row>
          )}
        />
      </SortableContext>
    </DndContext>
  )
}

function CategoryForm({ onSubmit, updateCategory, parentCategoryOptions, initialValues = {}, isLoading }) {
  const [form] = Form.useForm()

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }
    const subCategoriesList = form.getFieldValue('subCategories')
    const subCategories = reorder(subCategoriesList, result.source.index, result.destination.index)

    form.setFieldsValue({ subCategories })
    await Promise.all([
      updateCategory({ _id: subCategoriesList[result.source.index]._id, order: result.destination.index }),
      updateCategory({ _id: subCategoriesList[result.destination.index]._id, order: result.source.index }),
    ])
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="name" label={'שם הקטגוריה'}>
        <Input />
      </Form.Item>
      <Form.Item name="parentId" label={'קטגוריית אב'}>
        <Select options={parentCategoryOptions} />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="folderIds" label={'מספרי תיקיות'}>
        <Select mode="tags" dropdownStyle={{ display: 'none' }} placeholder="נא להוסיף מספרי תיקיות" />
      </Form.Item>
      <Form.Item name="isVisible" valuePropName="checked">
        <Checkbox>{'נראה למשתמש?'}</Checkbox>
      </Form.Item>

      {!isEmpty(initialValues) && !initialValues['parentId'] && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Form.List name="subCategories">
                  {(fields) => {
                    if (isEmpty(fields)) return null

                    return (
                      <>
                        <Row justify="space-between">
                          <Col>
                            <Form.Item>
                              <Typography.Text>{'תת קטגוריות'}</Typography.Text>
                            </Form.Item>
                          </Col>
                        </Row>

                        {fields?.map(({ key, name, ...field }, index) => (
                          <Draggable key={key} draggableId={String(key)} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <Row gutter={[8, 8]}>
                                  <Col>
                                    <Button type="text" {...provided.dragHandleProps}>
                                      <HolderOutlined />
                                    </Button>
                                  </Col>
                                  <Col flex={1}>
                                    <Form.Item
                                      {...field}
                                      name={[name, 'name']}
                                      style={{ marginBottom: 8, width: '100%' }}
                                      rules={[{ required: true, message: 'שדה חובה' }]}
                                    >
                                      <Input variant="borderless" readOnly />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </>
                    )
                  }}
                </Form.List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}

function TableRow({ children, ...props }) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'] ?? 'new-table',
  })

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: props.className.includes('ant-table-row-level-0') && (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
