import { Table, Input, Row, Col, Typography } from 'antd'
import { debounce } from 'lodash'
import { useRecords } from '../../hooks/useRecords'
import dayjs from 'dayjs'

export default function Records() {
  const { response, setParams, isLoading } = useRecords()
  const { data = [], page, pageSize, total } = response

  const onPaginationChange = (page, pageSize) => {
    setParams({ page, pageSize })
  }

  const onSearchChange = debounce((event) => {
    const { value } = event.target
    setParams({ search: value })
  }, 500)

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      rowKey="_id"
      bordered
      pagination={{
        pageSize,
        total,
        current: page,
        onChange: onPaginationChange,
        defaultPageSize: 20,
      }}
      columns={[
        {
          title: 'שם',
          dataIndex: 'key',
        },
        {
          title: 'ערך',
          dataIndex: 'value',
        },
        {
          title: 'IP',
          dataIndex: 'request',
          render: (request) => request.ip,
        },
        {
          title: 'User Agent',
          dataIndex: 'request',
          ellipsis: true,
          render: ({ userAgent }) => (
            <Typography.Paragraph ellipsis copyable={{ tooltips: userAgent, text: userAgent }}>
              {userAgent}
            </Typography.Paragraph>
          ),
        },
        {
          title: 'משתמש',
          dataIndex: 'user',
          ellipsis: true,
          render: (user) => user.email,
        },
        {
          align: 'left',
          dataIndex: 'createdAt',
          render: (createdAt) => dayjs(createdAt).format('DD/MM/YY HH:mm'),
        },
      ]}
      scroll={{ y: 'calc(100vh - 235px)' }}
      title={() => (
        <Row justify="space-between" align="center">
          <Col>
            <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
          </Col>
          <Col></Col>
        </Row>
      )}
    />
  )
}
