import React from 'react'

const HeaderBenefit = ({ benefitImageSrc, benefitUrl }) => {
  return (
    <a href={benefitUrl} target="_blank" rel="noopener noreferrer">
      <img src={benefitImageSrc} alt="benefit" style={{ width: '100%', borderRadius: 12 }} />
    </a>
  )
}

export default HeaderBenefit
