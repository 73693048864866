import { useState } from 'react'
import { Row, Col, Card, Typography, Input, Button, Form, message, Flex, Checkbox, Modal } from 'antd'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { validateEmail } from '../../utils/validators'

export default function NewsletterRegistration() {
  const [isLoading, setIsLoading] = useState(false)
  const { login } = useUser()
  const navigate = useNavigate()
  const [isPolicyAgreed, setIsPolicyAgreed] = useState(false)

  const onSubmit = async (values) => {
    if (!isPolicyAgreed) {
      return Modal.info({
        className: 'centered-content-modal',
        centered: true,
        title: 'יש לאשר את התנאים כדי להמשיך בהרשמה',
        icon: null,
      })
    }

    try {
      setIsLoading(true)
      await login(values, 'newsletter')
 
      navigate({
        pathname: '/login/verification',
        search: createSearchParams({
          flow: 'newsletter',
          redirect: '/newsletter/register/profile'
        }).toString()
      })

    } catch (error) {
      const { response = {} } = error
      const { data } = response
      message.error(data || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row justify="center" className="bg bg9">
      <Col xs={24} align="middle" flex="840px">
        <Row>
          <Col xs={24}>
            <Card
              className="content-card"
              styles={{
                body: {
                  padding: '48px 12px',
                },
              }}
              style={{ background: '#E5015B' }}
            >
              <Row justify="center" gutter={[24, 24]}>
                <Col xs={24} flex="650px">
                  <Flex justify="center" gap={24} vertical>
                    <Flex vertical>
                      <Typography.Title level={1} style={{ color: 'white', margin: 0 }}>
                        {`הירשמו לניוזלטר`}
                      </Typography.Title>
                      <Typography.Title level={1} style={{ color: 'white', margin: 0 }}>
                        {`של ערוץ הספורט ותקבלו:`}
                      </Typography.Title>
                    </Flex>
                    <Flex justify="center" align="baseline">
                      <IconBox src="/icons/microphone.svg" title={'תכנים וראיונות אקסקלוסיביים שאין בשום מקום'} />
                      <IconBox src="/icons/send.svg" title={'תוכן מותאם אישית ישר אליך'} />
                      <IconBox src="/icons/gift-card.svg" title={'הטבות מיוחדות'} isSoon />
                      <IconBox src="/icons/calendar-check.svg" title={'אירועי קהילה'} isSoon />
                    </Flex>
                  </Flex>
                </Col>
                <Col xs={24} flex="500px">
                  <Flex vertical gap={24}>
                    <Flex vertical gap={4}>
                      <Flex vertical gap={29}>
                        <Typography.Title level={2} style={{ marginBottom: 16, color: 'white', margin: 0 }}>
                          {`אז מה הדוא"ל שלך?`}
                        </Typography.Title>
                      </Flex>
                      <Typography.Text style={{ color: 'white' }}>{'*לשם יישלח קוד האימות'}</Typography.Text>
                    </Flex>
                    <Form onFinish={onSubmit} validateTrigger="onSubmit">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} md={17}>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: <div style={{ color: 'white' }}>{'יש להזין דוא״ל'}</div>,
                                validator: (_, email) => {
                                  if (!validateEmail(email)) {
                                    return Promise.reject('הכתובת אינה תקינה')
                                  }

                                  return Promise.resolve()
                                },
                              },
                            ]}
                          >
                            <Input
                              className="placeholder-rtl"
                              style={{ borderColor: 'white', direction: 'ltr' }}
                              placeholder="דוא׳׳ל שלי"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={7}>
                          <Button
                            loading={isLoading}
                            htmlType="submit"
                            block
                            shape="round"
                            style={{ background: '#FFA41C' }}
                            type="primary"
                          >
                            {'הרשמה'}
                          </Button>
                        </Col>
                        <Col xs={24}>
                          <Flex gap={12} align="flex-start">
                            <Checkbox
                              className="checkbox-white"
                              defaultChecked={isPolicyAgreed}
                              onChange={(e) => setIsPolicyAgreed(e.target.checked)}
                            />
                            <Typography.Text style={{ color: 'white', fontSize: 12, textAlign: 'right' }}>
                              {
                                'אני מאשר קבלת חומרים שיווקיים, עדכונים ומידע, של ערוץ הספורט ושל שותפיו העסקיים, באמצעות שיחות, מסרונים, דוא"ל או כל תקשורת דיגיטלית אחרת. תוכל בכל עת לבטל הסכמתך באמצעות פנייה בדוא"ל ל '
                              }
                              <a
                                style={{ color: 'white', textDecoration: 'underline' }}
                                href="mailto:support@rge.co.il"
                              >
                                {'support@rge.co.il'}
                              </a>
                              {` או באמצעות דרכי ההסרה שיפורטו בהודעה. `}
                            </Typography.Text>
                          </Flex>
                        </Col>
                      </Row>
                    </Form>
                  </Flex>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function IconBox({ title, src, isSoon }) {
  return (
    <Flex vertical gap={12} justify="center" align="center" style={{ width: 142 }}>
      <Button
        type="primary"
        shape="circle"
        className="icon-box"
        style={{
          background: 'transparent',
          border: '2px solid white',
          boxShadow: 'none',
          cursor: 'initial',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={src} alt="icon-btn" />
      </Button>
      <Flex vertical gap={4}>
        {isSoon && (
          <div>
            <Typography.Text
              className="icon-box-text"
              style={{ color: '#E5015B', background: '#BFBFBF', padding: '4px 12px', borderRadius: 4 }}
            >
              {'בקרוב'}
            </Typography.Text>
          </div>
        )}
        <Typography.Text className="icon-box-text" style={{ color: 'white' }}>
          {title}
        </Typography.Text>
      </Flex>
    </Flex>
  )
}
