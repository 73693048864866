import { isFunction } from 'lodash'

export function useExposebox() {
  const setCustomerData = (data = {}) => {
    if (!isFunction(window?.predictlApi)) return

    window?.predictlApi(() => {
      window?.predictlApi.setCustomerData(data)
    })
  }

  return {
    setCustomerData
  }
}
