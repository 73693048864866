export const getSessionStorageByKey = (key) => {
  const serializedData = sessionStorage.getItem(key)
  if (serializedData) {
    return JSON.parse(serializedData)
  }
  return null
}

export const setSessionStorageByKey = (key, value) => {
  const serializedData = JSON.stringify(value)
  sessionStorage.setItem(key, serializedData)
}
