import { useState } from 'react'
import { useQuery } from 'react-query'
import { useUser } from './useUser'
import axios from 'axios'

const endpoint = '/api/record'

export function useRecords() {
  const { user } = useUser()
  const [params, setParams] = useState({
    page: 1,
    pageSize: 20,
  })

  const queryFn = async () => {
    const { data } = await axios.get(endpoint, { params })
    return data
  }

  const { isLoading, data: response = {} } = useQuery({
    queryKey: [endpoint, params],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(user?.isAdmin),
  })

  const createRecord = (key, value) => {
    return axios.post(endpoint, { key, value })
  }

  return {
    isLoading,
    setParams,
    response,
    createRecord,
  }
}
