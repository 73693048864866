import { Form, Input, Button, Select } from 'antd'
import { UploadImage } from './UploadImage'
import axios from 'axios'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { basketballKey, israeliTeamKey, nbaKey, worldTeamKey } from '../../../utils/MyTeams.const'
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const TeamForm = ({ onSubmit, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState('')

  const getIconUrl = () => {
    if (imageUrl !== '') return imageUrl
    return !isEmpty(initialValues) ? initialValues.iconUrl : ''
  }

  const uploadToCloudinary = async ({ file }) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'my_sport5')

    try {
      const response = await axios.post('https://api.cloudinary.com/v1_1/rge1/image/upload', formData, config)

      setImageUrl(response.data.secure_url)
      form.setFieldValue('iconUrl', response.data.secure_url)
    } catch (error) {
      console.log('error ', error)
    }
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item name="iconUrl" valuePropName="fileList" label={'לוגו'}>
        <UploadImage uploadToCloudinary={uploadToCloudinary} iconUrl={getIconUrl()} />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="sportCategories" label={'קטגוריה'}>
        <Select
          options={[
            { value: israeliTeamKey, label: israeliTeamKey },
            { value: worldTeamKey, label: worldTeamKey },
            { value: basketballKey, label: basketballKey },
            { value: nbaKey, label: nbaKey },
          ]}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="name" label={'שם הקבוצה'}>
        <Input />
      </Form.Item>
      <Form.Item name="folderIds" label={'מספרי תיקיות'}>
        <Select mode="tags" dropdownStyle={{ display: 'none' }} placeholder="נא להוסיף מספרי תיקיות" />
      </Form.Item>
      <Form.Item name="tags" label={'תגיות'}>
        <Select mode="tags" dropdownStyle={{ display: 'none' }} placeholder="נא להוסיף תגיות" />
      </Form.Item>

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
