import { Space, Button, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import isFunction from 'lodash/isFunction'

export default function TableActions({ onUpdateClick, onDeleteClick }) {
  if (!isFunction(onUpdateClick) && !isFunction(onDeleteClick)) {
    return null
  }

  return (
    <Space>
      {isFunction(onUpdateClick) && (
        <Tooltip placement="top" title={'עריכה'}>
          <Button size="small" onClick={onUpdateClick} icon={<EditOutlined />} />
        </Tooltip>
      )}

      {isFunction(onDeleteClick) && (
        <Tooltip placement="top" title={'מחיקה'}>
          <Button size="small" onClick={onDeleteClick} icon={<DeleteOutlined />} />
        </Tooltip>
      )}
    </Space>
  )
}
