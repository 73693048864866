import { Button, Typography, Flex } from 'antd'
import { Link } from 'react-router-dom'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NotFound() {
  return (
    <div className="bg bg4">
      <HelmetHeader />
      <Flex style={{ minHeight: '100vh' }} align="center" justify="center" vertical gap={12}>
        <Typography.Title style={{ fontWeight: 700, fontSize: 80, margin: 0 }}>404</Typography.Title>
        <Link to="/">
          <Button shape="round" type="primary">
            {'חזרה לעמוד הראשי'}
          </Button>
        </Link>
      </Flex>
    </div>
  )
}
