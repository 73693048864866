import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Typography, theme, Form, Input, Button, message, Flex } from 'antd'
import moment from 'moment'
import ContentCard from '../../components/layouts/ContentCard'
import DateMaskInput from '../../components/elements/DateMaskInput'
import { useUser } from '../../hooks/useUser'
import { isEmpty } from 'lodash'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import { track } from '../../utils/analytics'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterRegistrationProfile() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const { updateNewsletterStatus, newsletter, isFetched } = useUserNewsletter()
  const { updateProfile, user } = useUser()
  const { token } = theme.useToken()
  const { setFieldsValue } = form

  useEffect(() => {
    if (isFetched && newsletter.status !== 'active') {
      updateNewsletterStatus('active', 'newsletter')
    }
  }, [isFetched, newsletter.status])

  useEffect(() => {
    if (!isEmpty(user)) {
      const { firstName = '', lastName = '', birthDate = '' } = user

      setFieldsValue({
        firstName,
        lastName,
        birthDate: birthDate ? moment(birthDate).format('DD/MM/YYYY') : '',
      })
    }
  }, [user, setFieldsValue])

  const onSubmit = async (values) => {
    try {
      if (!isEmpty(values)) {
        setIsLoading(true)
        const { firstName, lastName, birthDate } = values
        const payload = {}

        if (firstName) payload.firstName = firstName
        if (lastName) payload.lastName = lastName
        if (birthDate) {
          const date = moment.utc(birthDate, 'DD/MM/YYYY')

          if (date.isValid()) {
            payload.birthDate = date.format()
          }
        }

        await updateProfile(payload)

        track({
          event_name: 'form_submit',
          form_name: 'onboarding_profile',
          purpose: 'user_profile',
          flow: 'newsletter',
        })
      }

      navigate('/newsletter/register/preferred-sports')
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onInputFocus = () => {
    if (!isInputFocused) {
      setIsInputFocused(true)
      track({
        event_name: 'form_start',
        form_name: 'onboarding_profile',
        purpose: 'user_profile',
        flow: 'newsletter',
      })
    }
  }

  return (
    <Form form={form} onFinish={onSubmit} validateTrigger="onSubmit">
      <HelmetHeader />
      <Row className="bg bg2" justify="center">
        <Col align="middle" flex="724px">
          <ContentCard>
            <Row gutter={[24, 24]} justify="center">
              <Col flex="550px">
                <Flex vertical gap={24} align="center">
                  <Flex vertical justify="center" align="center">
                    <Typography.Title style={{ color: token.colorPrimary, maxWidth: 340 }} level={3}>
                      {'נרשמת בהצלחה לניוזלטר של ערוץ הספורט!'}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: 24, maxWidth: 500 }}>
                      {'בואו נתחיל בהיכרות קצרה- אנחנו ערוץ הספורט, איך קוראים לך?'}
                    </Typography.Text>
                  </Flex>

                  <Row gutter={[0, 24]}>
                    <Col xs={24}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} sm={12}>
                          <Form.Item name="firstName" rules={[{ required: true, message: 'יש להזין שם פרטי' }]}>
                            <Input placeholder="שם פרטי" onFocus={onInputFocus} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item name="lastName" rules={[{ required: true, message: 'יש להזין שם משפחה' }]}>
                            <Input placeholder="שם משפחה" onFocus={onInputFocus} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24}>
                      <Flex vertical gap={24} align="center">
                        <div style={{ maxWidth: 360 }}>
                          <Typography.Text style={{ fontSize: 24 }}>
                            לא בא לנו לשכוח להגיד לך מזל טוב. מתי נולדת?
                          </Typography.Text>
                        </div>
                        <Form.Item
                          name="birthDate"
                          rules={[
                            {
                              required: true,
                              message: 'יש להזין תאריך לידה',
                            },
                            {
                              validator: (_, value) => {
                                const date = moment(value, 'DD/MM/YYYY')
                                const now = moment()

                                if (!date.isValid() || date?.isAfter(now)) return Promise.reject('התאריך אינו תקין')
                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <DateMaskInput onFocus={onInputFocus} />
                        </Form.Item>
                      </Flex>
                    </Col>
                  </Row>

                  <Button
                    block
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    style={{ maxWidth: 171 }}
                    loading={isLoading}
                  >
                    {'המשך'}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </ContentCard>
        </Col>
      </Row>
    </Form>
  )
}
