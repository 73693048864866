import React from 'react'
import { Card, Flex, Typography } from 'antd'
import ExclusiveContentIcon from '../../assets/icons/exclusiveContent.svg'
import MyTeamsIcon from '../../assets/icons/myTeams.svg'

import IconComponent from '../elements/IconComponent'

const { Text, Title, Paragraph } = Typography
export const ExclusiveMobileArticle = ({
  imageUrl,
  date,
  title,
  description,
  authorName,
  teamName,
  exclusiveContent,
}) => (
  <Card
    hoverable
    styles={{ body: { padding: '10px 17px 17px' } }}
    style={{ height: '500px' }}
    cover={<img alt="Article" src={imageUrl} style={{ objectFit: 'inherit', height: '290px' }} />}
  >
    <Flex justify="space-between" gap={5} style={{ alignItems: 'center' }}>
      <Flex gap={10} style={{ alignItems: 'center', height: 65 }}>
        <IconComponent src={teamName ? MyTeamsIcon : ExclusiveContentIcon} />
        <Flex vertical>
          <Text strong>{exclusiveContent || teamName}</Text>
          <Text>
            {authorName} | {date}
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <Flex align={'center'} style={{ marginBottom: '16px' }}>
      <Title
        level={4}
        style={{
          marginBottom: 0,
          textAlign: 'right',
        }}
      >
        {title}
      </Title>
    </Flex>
    <Paragraph style={{ textAlign: 'right' }}>{description}</Paragraph>
  </Card>
)

export const ExclusiveArticle = ({ imageUrl, date, title, description, authorName }) => (
  <Card
    size={'small'}
    style={{ border: '1px solid rgba(228, 228, 228, 0.75)', width: '340px', height: '440px' }}
    hoverable
    styles={{
      body: {
        width: '100%',
      },
    }}
    cover={
      <img
        alt="Article"
        width={340}
        src={imageUrl}
        style={{ objectFit: 'inherit', height: '240px', borderRadius: '0 12px 12px 0' }}
      />
    }
  >
    <Title level={4} style={{ margin: 0 }}>
      {title}
    </Title>
    <Paragraph style={{ textAlign: 'right' }}>{description}</Paragraph>
    <Text
      style={{
        textAlign: 'left',
      }}
    >
      {authorName} | {date}
    </Text>
  </Card>
)

export const DesktopArticle = ({ imageUrl, date, title, description, authorName, exclusiveContent, teamName }) => (
  <Card
    size={'small'}
    style={{ display: 'flex', border: '1px solid rgba(228, 228, 228, 0.75)', width: '740px', height: '200px' }}
    hoverable
    styles={{
      body: {
        width: '100%',
      },
    }}
    cover={
      <img
        alt="Article"
        width={300}
        src={imageUrl}
        style={{ objectFit: 'inherit', height: '101%', width: '290px', borderRadius: '0 12px 12px 0' }}
      />
    }
  >
    <Flex vertical={true} align={'center'} style={{ marginBottom: '10px' }}>
      <Flex align={'center'} justify={'space-between'} style={{ width: '100%' }}>
        <Flex gap={10} style={{ alignItems: 'center', height: 65 }}>
          <IconComponent src={teamName ? MyTeamsIcon : ExclusiveContentIcon} />
          <Typography.Text strong>{exclusiveContent || teamName}</Typography.Text>
        </Flex>

        <Typography.Text
          style={{
            textAlign: 'left',
            color: 'rgba(38, 38, 38, 0.60)',
            fontWeight: '400',
          }}
        >
          {authorName} | {date}
        </Typography.Text>
      </Flex>
    </Flex>
    <Typography.Title level={4} style={{ margin: 0 }}>
      {title}
    </Typography.Title>
    <Typography.Paragraph style={{ textAlign: 'right' }}>{description}</Typography.Paragraph>
  </Card>
)
