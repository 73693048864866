import { useEffect } from 'react'
import { Row, Col, Typography, Flex } from 'antd'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import { useExposebox } from '../../hooks/useExposebox'
import { useUser } from '../../hooks/useUser'
import NewsletterFrequencyForm from './components/NewsletterFrequencyForm'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterFrequency() {
  const { user } = useUser()
  const userSettings = useUserNewsletter()
  const exposebox = useExposebox()
  const { newsletter = {} } = userSettings
  const { status } = newsletter
  const { setCustomerData } = exposebox

  useEffect(() => {
    if (user?.isVerified && status === 'active') {
      setCustomerData({ customerId: user.email })
    }
  }, [user, status, setCustomerData])

  return (
    <Row justify="center" className="bg bg6">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          loading={userSettings.isLoading}
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row gutter={[24, 24]} justify="center">
            <Col flex="600px">
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Flex justify="center">
                    <Typography.Title level={3} style={{ maxWidth: 400 }}>
                      {'כל כמה זמן תרצה לקבל מאיתנו עדכונים על עולם הספורט?'}
                    </Typography.Title>
                  </Flex>
                </Col>
                <Col xs={24}>
                  <NewsletterFrequencyForm />
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
