import { Navigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useUserNewsletter } from '../hooks/useUserNewsletter'
import { useGetUserPreferredSports } from '../hooks/usePreferredSports'

export default function RequiredNewsletterRoute({ children }) {
  const { newsletter } = useUserNewsletter()
  const { data: preferredSports, isFetched: isUserPreferredSportsFetched } = useGetUserPreferredSports()

  if ((isUserPreferredSportsFetched && isEmpty(preferredSports)) || (!isEmpty(newsletter) && newsletter.status !== 'active')) {
    return <Navigate to="/newsletter/register" />
  }

  return children
}
