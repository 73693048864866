import { Navigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useUser } from '../hooks/useUser'
import Loader from '../components/layouts/Loader'

export default function RequiredAuthenticationRoute({ children }) {
  const { user, isLoading } = useUser()

  if (isLoading) {
    return <Loader />
  }

  if (isEmpty(user)) {
    return <Navigate to="/login" state={{ user }} />
  }

  return children
}
