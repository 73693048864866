import { isEmpty } from 'lodash'
import { SelectTeam } from './SelectTeam'
import { basketballKey, israeliTeamKey, nbaKey, worldTeamKey } from '../../../utils/MyTeams.const'

export const SelectFavoriteTeams = ({ sportCategoriesOptions }) => {
  return (
    <>
      {!isEmpty(sportCategoriesOptions.israeliFootball) && (
        <SelectTeam
          options={sportCategoriesOptions.israeliFootball}
          formLabel={'איזה קבוצת כדורגל ישראלי נמצאת אצלך בלב?'}
          formName={israeliTeamKey}
        />
      )}
      {!isEmpty(sportCategoriesOptions.worldFootball) && (
        <SelectTeam
          options={sportCategoriesOptions.worldFootball}
          formLabel={'מי הקבוצה שלך בכדורגל העולמי?'}
          formName={worldTeamKey}
        />
      )}
      {!isEmpty(sportCategoriesOptions.basketball) && (
        <SelectTeam
          options={sportCategoriesOptions.basketball}
          formLabel={'איזה קבוצת כדורסל היא האחת שלך?'}
          formName={basketballKey}
        />
      )}
      {!isEmpty(sportCategoriesOptions.nba) && (
        <SelectTeam options={sportCategoriesOptions.nba} formLabel={'מי הפייבוריטית שלך ב NBA?'} formName={nbaKey} />
      )}
    </>
  )
}
