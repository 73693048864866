import { Row, Col, Avatar, Typography, Button, Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import ContentCard from '../../components/layouts/ContentCard'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterComplete() {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/')
  }

  return (
    <Row justify="center" className="bg bg7">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard>
          <Row justify="center">
            <Col flex="600px">
              <Flex vertical gap={24} align="center">
                <Avatar size={120} shape="square" src="/icons/ok.svg" />
                <Typography.Title level={1}>{'קיבלנו!'}</Typography.Title>
              </Flex>
              <Flex justify="center" vertical gap={40}>
                <Typography.Text>{'ההגדרות עודכנו במערכת שלנו בהצלחה'}</Typography.Text>
                <div>
                  <Button style={{ marginBottom: 24 }} onClick={onClick} type="primary" shape="round">
                    {'חזרה לאזור האישי'}
                  </Button>
                </div>
              </Flex>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
