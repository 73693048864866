import React from 'react'
import { Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { isFunction } from 'lodash'

export default function BackButton({ onClick, hidden, ...rest }) {
  const navigate = useNavigate()

  if (hidden) return null

  return (
    <Button
      shape="round"
      icon={<ArrowRightOutlined />}
      onClick={() => (isFunction(onClick) ? onClick() : navigate(-1))}
      size="small"
      type="text"
      {...rest}
    >
      {'חזרה'}
    </Button>
  )
}
