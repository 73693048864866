import { useState } from 'react'
import { Form, Radio, message, Button, Row, Col, Typography, Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useUserNewsletter } from '../../../hooks/useUserNewsletter'

const options = [
  {
    label: 'פעם ביום',
    value: 'once a day',
  },
  {
    label: 'פעם ביומיים',
    value: 'every other day',
  },
  {
    label: 'פעם בשבוע',
    value: 'once a week',
  },
]

export default function NewsletterFrequencyForm() {
  const [isLoading, setIsLoading] = useState(false)
  const { newsletter, updateNewsletterFrequency } = useUserNewsletter()
  const navigate = useNavigate()

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      await updateNewsletterFrequency(values)

      navigate('/newsletter/complete')
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ frequency: newsletter?.frequency || options[0].value }}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Form.Item name="frequency">
            <Radio.Group>
              <Flex vertical gap={12}>
                {options.map((option) => (
                  <Radio
                    key={option.value}
                    value={option.value}
                    data-gtm-event_name="element_click"
                    data-gtm-level1={option.label}
                    data-gtm-purpose="frequency"
                    data-gtm-type="selection"
                    data-gtm-flow="main"
                  >
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>{option.label}</Typography.Text>
                  </Radio>
                ))}
              </Flex>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24}></Col>
      </Row>

      <Form.Item>
        <Button htmlType="submit" shape="round" loading={isLoading} type="primary">
          {'שמירה'}
        </Button>
      </Form.Item>
    </Form>
  )
}
