import { Avatar, Flex, Tabs, Typography } from 'antd'
import { useTeamsResponsive } from '../../hooks/useTeamsResponsive'
const { Title, Text } = Typography

export const MyTeamsHeader = ({ activeTab, teams, onTabChange }) => {
  const { displayMode } = useTeamsResponsive(teams.length)

  if (displayMode === 'mobile')
    return (
      <Flex vertical align="center">
        <Title level={1} style={{ marginBottom: 0, marginTop: 10, alignSelf: 'center' }}>
          {teams?.length > 1 ? 'הקבוצות שלי' : 'הקבוצה שלי'}
        </Title>
        <Tabs
          activeKey={activeTab}
          style={{ width: '100%' }}
          items={teams?.map((team) => ({
            key: team._id,
            label: <Avatar size={65} src={team.iconUrl} shape="square" />,
          }))}
          onChange={onTabChange}
        />
      </Flex>
    )

  if (displayMode === 'tablet')
    return (
      <Flex align="center" className="my-teams-header">
        <Title level={1} style={{ margin: '0px 24px 0px 0px', alignSelf: 'center', minWidth: '200px' }}>
          הקבוצות שלי
        </Title>
        <Tabs
          activeKey={activeTab}
          style={{ width: '65%' }}
          items={teams?.map((team) => ({
            key: team._id,
            label: <Avatar size={65} src={team.iconUrl} shape="square" />,
          }))}
          onChange={onTabChange}
        />
      </Flex>
    )

  return (
    <Flex justify="space-between">
      <Title level={1} style={{ margin: '0px 24px 0px 0px', alignSelf: 'center', minWidth: '200px' }}>
        {teams?.length > 1 ? 'הקבוצות שלי' : 'הקבוצה שלי'}
      </Title>
      <Flex>
        <Tabs
          activeKey={activeTab}
          items={teams?.map((team) => ({
            key: team._id,
            label: <Text strong>{team.name}</Text>,
            icon: <Avatar size={65} src={team.iconUrl} shape="square" style={{ marginRight: 28 }} />,
          }))}
          onChange={onTabChange}
        />
      </Flex>
    </Flex>
  )
}
