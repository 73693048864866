import { useQuery } from 'react-query'
import axios from 'axios'

const endpoint = '/api/sport-categories'

export function useSportCategories() {
  const {
    isLoading,
    data: response = {},
    refetch,
  } = useQuery({
    queryKey: [endpoint],
    queryFn: () => axios.get(endpoint),
  })

  const createCategory = async (values) => {
    await axios.post(endpoint, values)
    refetch()
  }

  const deleteCategory = async (id, parentCategoryId) => {
    await axios.delete(`${endpoint}/${id}`, {
      params: { parentCategoryId },
    })

    refetch()
  }

  const updateCategory = async (values) => {
    await axios.put(`${endpoint}/${values._id}`, values)
    refetch()
  }

  const { data = [] } = response

  return {
    isLoading,
    data,
    createCategory,
    deleteCategory,
    updateCategory,
  }
}
