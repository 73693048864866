import { useEffect, useMemo, useState } from 'react'
import { Row, Col, Form, Input, Select, Button, Typography, message, Modal, Flex } from 'antd'
import { useUser } from '../../hooks/useUser'
import ContentCard from '../../components/layouts/ContentCard'
import DateMaskInput from '../../components/elements/DateMaskInput'
import moment from 'moment'
import { isEmpty, isEqual } from 'lodash'
import { pick } from 'lodash'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { track } from '../../utils/analytics'

export default function Profile() {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isPhoneVerificationModalOpen, setIsPhoneVerificationModalOpen] = useState(false)
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false)
  const { user = {}, updateProfile } = useUser()
  const { setFieldsValue } = form

  const isPhoneNumberNotVerified = useMemo(() => user.phoneNumber && !user.isPhoneNumberVerified, [user])

  useEffect(() => {
    if (!isEmpty(user)) {
      setFieldsValue({
        ...user,
        birthDate: user.birthDate && moment(user.birthDate).format('DD/MM/YYYY'),
      })
    }
  }, [user, setFieldsValue])

  const openPhoneVerificationModal = () => {
    setIsPhoneVerificationModalOpen(true)
  }

  const closePhoneVerificationModal = () => {
    setIsPhoneVerificationModalOpen(false)
  }

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      const { birthDate, phoneNumber } = values
      const payload = { ...values }

      track({
        event_name: 'form_submit',
        form_name: 'user_info',
        flow: 'main',
        purpose: 'user_info',
      })

      if (birthDate) {
        const date = moment.utc(birthDate, 'DD/MM/YYYY')
        if (date.isValid()) {
          payload.birthDate = date.format()
        }
      }

      if (birthDate === 'dd/mm/yyyy') {
        payload.birthDate = null
      }

      const isChanged = getIsFormFieldsChanged(values)

      if (isChanged) {
        await updateProfile(payload)
        message.success('הפרופיל עודכן בהצלחה')
      }

      if (phoneNumber && !user.isPhoneNumberVerified) {
        setIsPhoneVerificationModalOpen(true)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getIsFormFieldsChanged = (values) => {
    const fields = ['firstName', 'lastName', 'gender', 'phoneNumber']
    const isDateChanged = !moment(values.birthDate, 'DD/MM/YYYY').isSame(user.birthDate, 'date')
    return isDateChanged || !isEqual(pick(values, fields), pick(user, fields))
  }

  return (
    <Row justify="center" className="bg bg8">
      <HelmetHeader />
      <Col flex="600px">
        <ContentCard>
          <Row justify="center">
            <Col flex="300px">
              <Typography.Title level={1}>{'פרופיל'}</Typography.Title>

              <Form form={form} layout="vertical" validateTrigger="onSubmit" onFinish={onSubmit}>
                <Form.Item label="אימייל" name="email" style={{ cursor: 'not-allowed' }}>
                  <Input style={{ pointerEvents: 'none' }} />
                </Form.Item>

                <Form.Item label="שם פרטי" name="firstName" rules={[{ required: true }, { max: 20 }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="שם משפחה" name="lastName" rules={[{ required: true }, { max: 20 }]}>
                  <Input />
                </Form.Item>
                <Flex align="center" gap="small">
                  <Form.Item
                    label="מספר טלפון"
                    name="phoneNumber"
                    rules={[
                      {
                        validator: (_, phoneNumber) => {
                          if (phoneNumber && !isValidPhoneNumber(phoneNumber, 'IL')) {
                            setIsPhoneNumberError(true)
                            return Promise.reject('המספר אינו תקין')
                          }

                          setIsPhoneNumberError(false)
                          return Promise.resolve()
                        },
                      },
                    ]}
                    style={{ width: '100%' }}
                    validateStatus={isPhoneNumberNotVerified && !isPhoneNumberError ? 'warning' : undefined}
                    help={isPhoneNumberNotVerified && !isPhoneNumberError ? 'מספר לא מאומת' : undefined}
                  >
                    <Input style={{ direction: 'ltr' }} />
                  </Form.Item>
                  {isPhoneNumberNotVerified && (
                    <Form.Item label=" ">
                      <Button type="primary" onClick={openPhoneVerificationModal}>
                        {'אימות'}
                      </Button>
                    </Form.Item>
                  )}
                </Flex>

                <Form.Item label="מין" name="gender">
                  <Select
                    options={[
                      { value: 'male', label: 'זכר' },
                      { value: 'female', label: 'נקבה' },
                      { value: 'other', label: 'אחר' },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="תאריך לידה"
                  name="birthDate"
                  rules={[
                    {
                      validator: (_, value) => {
                        const date = moment(value, 'DD/MM/YYYY')
                        const now = moment()

                        if (value && value !== 'dd/mm/yyyy' && (!date.isValid() || date?.isAfter(now))) {
                          return Promise.reject('התאריך אינו תקין')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <DateMaskInput />
                </Form.Item>

                <br />
                <Button loading={isLoading} htmlType="submit" block shape="round" type="primary">
                  {'שמור'}
                </Button>
              </Form>
            </Col>
          </Row>
        </ContentCard>
      </Col>
      <Modal
        open={isPhoneVerificationModalOpen}
        onCancel={closePhoneVerificationModal}
        footer={false}
        title="אימות מספר טלפון"
        width={525}
      >
        <ProfilePhoneVerification closePhoneVerificationModal={closePhoneVerificationModal} />
      </Modal>
    </Row>
  )
}

function ProfilePhoneVerification({ closePhoneVerificationModal }) {
  const { user = {}, sendPhoneNumberVerification, verifyPhoneNumber } = useUser()
  const [isPhoneVerificationSent, setIsPhoneVerificationSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { phoneNumber } = user

  const onSubmitPhoneNumber = async ({ phoneNumber }) => {
    try {
      setIsLoading(true)
      await sendPhoneNumberVerification(phoneNumber)
      setIsPhoneVerificationSent(true)
    } catch (error) {
      let errorMessage = error.message
      const isPhoneNumberBlocked = error?.response?.status === 429
      if (isPhoneNumberBlocked) {
        errorMessage = 'המספר חסום לשליחת מסרונים'
      }

      message.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitPhoneVerification = async ({ verificationCode }) => {
    try {
      setIsLoading(true)
      await verifyPhoneNumber(verificationCode)
      closePhoneVerificationModal()
      message.success('המספר אומת בהצלחה')
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isPhoneVerificationSent) {
    return (
      <Form layout="vertical" onFinish={onSubmitPhoneVerification} validateTrigger="onSubmit" requiredMark={false}>
        <Form.Item
          name="verificationCode"
          label={`שלחנו קוד אימות ל-${phoneNumber} יש להזין את הקוד כאן:`}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Button loading={isLoading} type="primary" block htmlType="submit" shape="round">
          {'אישור'}
        </Button>
      </Form>
    )
  }

  return (
    <Form
      initialValues={{ phoneNumber }}
      layout="vertical"
      onFinish={onSubmitPhoneNumber}
      validateTrigger="onSubmit"
      requiredMark={false}
    >
      <Form.Item
        name="phoneNumber"
        label={'ראינו שהזנת מספר טלפון חדש, אנחנו רק רוצים לוודא שזה המספר הנכון'}
        rules={[
          {
            required: true,
            message: 'שדה חובה',
          },
          {
            validator: (_, phoneNumber) => {
              if (phoneNumber && !isValidPhoneNumber(phoneNumber, 'IL')) {
                return Promise.reject('המספר אינו תקין')
              }

              return Promise.resolve()
            },
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Button loading={isLoading} type="primary" block htmlType="submit" shape="round">
        {'כן, שלחו לי קוד אימות'}
      </Button>
    </Form>
  )
}
