import { useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Input, Row, Col, Typography, Flex } from 'antd'
import BackButton from '../../components/elements/BackButton'
import { isEmpty } from 'lodash'
import { ReactComponent as PauseReason1 } from '../../assets/icons/subscription-pause-reason-1.svg'
import { ReactComponent as PauseReason2 } from '../../assets/icons/subscription-pause-reason-2.svg'
import ContentCard from '../../components/layouts/ContentCard'
import { useRecords } from '../../hooks/useRecords'
import { track } from '../../utils/analytics'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

const options = [
  {
    Icon: PauseReason1,
    title: 'התוכן לא היה מספיק רלוונטי',
  },
  {
    Icon: PauseReason2,
    title: 'כמות גדולה מידי של מיילים',
  },
]

export default function NewsletterUnsubscriptionSurvey() {
  const { createRecord } = useRecords()
  const [selectedOption, setSelectedOption] = useState()
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const { period } = location.state

  const periodLabel = useMemo(() => {
    const isUnsubscribed = isEmpty(period)
    if (isUnsubscribed) return ''

    return `אוקי, בהתאם לבקשתך נקפיא את שליחת הניוזלטר.`
  }, [period])

  const onSubmit = async () => {
    const value = selectedOption || inputValue

    try {
      setIsLoading(true)
      await createRecord('newsletter', `unsibscription reason: ${value}`)

      track({
        event_name: 'form_submit',
        form_name: 'newsletter_unsubscription_survey',
        purpose: 'unsubscribe_reason',
        flow: 'unsubscribe',
      })

      navigate('/newsletter/unsubscription/complete')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  const onTextChange = (value) => {
    setInputValue(value)
    if (selectedOption) {
      setSelectedOption()
    }
  }

  const onTextAreaFocus = () => {
    if (!isTextAreaFocused) {
      setIsTextAreaFocused(true)
      track({
        event_name: 'form_start',
        form_name: 'newsletter_unsubscription_survey',
        purpose: 'unsubscribe_reason',
        flow: 'unsubscribe',
      })
    }
  }

  const onSelect = (value) => {
    setSelectedOption(value)
    if (inputValue) {
      setInputValue()
    }
  }

  return (
    <Row justify="center" className="bg bg7">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row justify="center" gutter={[24, 24]}>
            <Col flex="600px">
              <Typography.Title style={{ fontWeight: 400 }} level={3}>
                {periodLabel}
              </Typography.Title>
              <Typography.Title style={{ fontWeight: 400 }} level={3}>
                {`בכל אופן, נשמח לדעת מה הסיבה בגללה בחרת להקפיא את הניוזלטר?`}
              </Typography.Title>
            </Col>
            <Col flex="350px">
              <Row gutter={[24, 24]}>
                {options.map((option) => {
                  const { Icon, title } = option

                  return (
                    <Col xs={12} key={title}>
                      <Flex
                        vertical
                        gap={12}
                        align="center"
                        className={`icon-button-svg ${selectedOption === title ? 'icon-button-svg-active' : ''}`}
                        onClick={() => onSelect(title)}
                        data-gtm-event_name="element_click"
                        data-gtm-description={title}
                        data-gtm-flow="unsubscribe"
                        data-gtm-purpose="unsubscribe_reason_selection"
                        data-gtm-type="selection"
                      >
                        <Icon />
                        <span>{title}</span>
                      </Flex>
                    </Col>
                  )
                })}

                <Col xs={24}>
                  <Input.TextArea
                    placeholder="אחר (אנא פרט):"
                    value={inputValue}
                    onChange={(e) => onTextChange(e.target.value)}
                    onFocus={onTextAreaFocus}
                    rows={6}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <br />
              <Button loading={isLoading} type="primary" disabled={!selectedOption && !inputValue} onClick={onSubmit}>
                {'המשך'}
              </Button>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
