import React from 'react'
import TagManager from 'react-gtm-module'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/index.css'

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-ND6T5RNP',
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
