import { Row, Col, Flex } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'
import RegisterFavoriteTeamsForm from './components/RegisterFavoriteTeamsForm'
import { useMemo } from 'react'
import { useGetUserPreferredSports } from '../../hooks/usePreferredSports'
import { useTeams } from '../../hooks/useTeams'
import { createTeamOptionsForRegister } from './MyTeams.utils'

export default function RegisterPreferredTeams() {
  const { data: preferredSports } = useGetUserPreferredSports()
  const { data } = useTeams()

  const sportCategories = useMemo(
    () =>
      data.reduce((acc, team) => createTeamOptionsForRegister(acc, team, preferredSports), {
        israeliFootball: [],
        worldFootball: [],
        basketball: [],
        nba: [],
      }),
    [data, preferredSports],
  )

  return (
    <Row className="bg bg5" justify="center">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <RegisterFavoriteTeamsForm
            sportCategoriesOptions={sportCategories}
            formTitle={'מהיום אפשר לבחור את הקבוצות שלך'}
            navigateTo={'/'}
            showWelcomePopup
          />
        </ContentCard>
      </Col>
    </Row>
  )
}
