import { useMemo } from 'react'
import { Button, Form, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { SelectFavoriteTeams } from './SelectFavoriteTeams'
import { isEmpty } from 'lodash'
import { useUserPreferredTeams } from '../../../hooks/useUserPreferredTeams'
import { useUser } from '../../../hooks/useUser'

export default function RegisterFavoriteTeamsForm({
  sportCategoriesOptions,
  formTitle,
  navigateTo = '/',
  showWelcomePopup = false,
}) {
  const { data, isLoading, refetch } = useUserPreferredTeams()
  const { updateProfile } = useUser()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const initialValues = useMemo(() => {
    return (
      data?.reduce((acc, team) => {
        const categories = team.sportCategories
        acc[categories[0].name] = team._id
        return acc
      }, {}) || {}
    )
  }, [data])

  const onSubmit = async (values) => {
    const data = Object.values(values).filter((id) => id)
    await updateProfile({ disablePopup: true, preferredTeams: data })
    refetch()
    if (showWelcomePopup) {
      navigate(navigateTo, {
        state: {
          isWelcomePopup: true,
        },
      })
      return
    }
    navigate(navigateTo)
  }

  return (
    <>
      {!isEmpty(formTitle) && (
        <Typography.Title level={2} style={{ marginBottom: 12, fontSize: '28px' }}>
          <span>{formTitle}</span>
        </Typography.Title>
      )}
      <Form
        layout="vertical"
        style={{ maxWidth: '391px' }}
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <SelectFavoriteTeams sportCategoriesOptions={sportCategoriesOptions} />
        <Button shape="round" loading={isLoading} type="primary" htmlType="submit">
          המשך
        </Button>
      </Form>
    </>
  )
}
