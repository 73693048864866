import { Col, Card, Typography } from 'antd'

const ArticlesHeader = ({ titleContent }) => {
  return (
    <Col xs={24}>
      <Card style={{ background: 'unset' }}>
        <Typography.Title level={1}>{titleContent}</Typography.Title>
      </Card>
    </Col>
  )
}

export default ArticlesHeader
