import { useEffect, useMemo, useRef, useState } from 'react'
import { Tree, Button, Flex, Popover } from 'antd'
import { isEmpty } from 'lodash'
import { useGetUserPreferredSports } from '../../hooks/usePreferredSports'
import { track } from '../../utils/analytics'
import { useSportCategories } from '../../hooks/useSportCategories'

export default function SportCategoriesForm({ submitText, isLoading, onSubmit, gaFlow }) {
  const { data = [] } = useSportCategories()
  const { data: preferredSports = [] } = useGetUserPreferredSports()
  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const treeDataRef = useRef()
  const isDisabled = isEmpty(checkedKeys)

  useEffect(() => {
    setCheckedKeys(preferredSports)
  }, [preferredSports])

  const treeData = useMemo(
    () =>
      data.reduce((acc, category) => {
        if (category.isVisible) {
          acc.push({
            title: category.name,
            key: `category_${category.name}`,
            children: category.subCategories.reduce((acc, subCategory) => {
              if (subCategory.isVisible)
                acc.push({
                  title: subCategory.name,
                  key: `${category.name}_${subCategory.name}`,
                  parent: category.name,
                })
              return acc
            }, []),
          })
        }
        return acc
      }, []),
    [data],
  )

  const onCheck = (checkedKeys, event) => {
    const { node } = event
    const { title, parent } = node

    if (event.checked && !parent) {
      setExpandedKeys((prevState) => [...prevState, node.key])
    }

    let level1, level2

    if (parent) {
      level1 = parent
      level2 = title
    } else {
      level1 = title
    }

    track({
      event_name: 'element_click',
      level1,
      level2,
      purpose: 'interests',
      flow: gaFlow,
      type: 'selection',
    })

    setCheckedKeys(checkedKeys)
  }

  const onSubmitClick = () => {
    const { checkedKeys } = treeDataRef.current.state

    const nodes = treeData.reduce((acc, node) => {
      acc = acc.concat(node.children)
      return acc
    }, [])

    const checkedNodes = nodes.filter((node) => checkedKeys.includes(node.key))
    onSubmit(checkedNodes)
  }

  return (
    <Flex vertical gap={24}>
      <Tree
        checkable
        multiple
        onCheck={onCheck}
        ref={treeDataRef}
        treeData={treeData}
        virtual={false}
        autoExpandParent
        selectable={false}
        checkedKeys={checkedKeys}
        onExpand={setExpandedKeys}
        expandedKeys={expandedKeys}
      />

      <Flex justify="center">
        <Popover content={isDisabled && <span>{'יש לבחור את התכנים שמעניינים אותך'}</span>}>
          <Button disabled={isDisabled} shape="round" loading={isLoading} onClick={onSubmitClick} type="primary">
            {submitText}
          </Button>
        </Popover>
      </Flex>
    </Flex>
  )
}
