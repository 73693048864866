import { Menu, Affix, Row, Col } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'

function Container({ children }) {
  if (window.innerWidth < 900) {
    return <>{children}</>
  }

  return (
    <Affix>
      <div>{children}</div>
    </Affix>
  )
}

export default function AdminSidebar() {
  const { logOut } = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  const onClick = ({ key }) => {
    switch (key) {
      case 'logout':
        logOut()
        break
      default:
        navigate(key)
        break
    }
  }

  const menu = [
    {
      key: '/admin/users',
      label: 'משתמשים',
    },
    {
      key: '/admin/sport-categories',
      label: 'ענפי ספורט',
    },
    {
      key: '/admin/teams',
      label: 'קבוצות',
    },
    {
      key: '/admin/records',
      label: 'רשומות',
    },
    {
      key: '/admin/logs',
      label: 'לוגים',
    },
  ]

  return (
    <Container>
      <Row justify="center">
        <Col>
          <Link to="/">
            <img src="/images/logo.png" alt="app-logo" style={{ maxWidth: 70, padding: '24px 0' }} />
          </Link>
        </Col>
      </Row>

      <Menu onClick={onClick} selectedKeys={[location.pathname]} items={menu} />
    </Container>
  )
}
