import React, { useEffect, useState } from 'react'
import { useUser } from '../../hooks/useUser'
import menuIcon from '../../assets/icons/menu-icons/menu-icon.svg'
import UserMenu from './UserMenu'
import MobileUserMenu from './MobileUserMenu'
import AppSidebar from './AppSidebar'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Layout, theme, Flex } from 'antd'
import { getSessionStorageByKey, setSessionStorageByKey } from '../../utils/session'
import { useResponsive } from '../../hooks/useResponsive'

const { Header, Content } = Layout

export default function AppLayout({ hideMenu = false }) {
  const { token } = theme.useToken()
  const { user, isVerified } = useUser()
  const { isMobileView } = useResponsive()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const history = getSessionStorageByKey('history') || []
    history.push(location.pathname)
    setSessionStorageByKey('history', history)
  }, [location])

  const headerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    boxShadow: '0 -10px 20px 5px rgba(0,0,0,0.1)',
    background: token.colorPrimary,
  }

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const onLogoClick = () => {
    if (!hideMenu) {
      navigate('/')
    } else {
      window.location.href = 'https://www.sport5.co.il'
    }
  }

  return (
    <Layout>
      <Header style={{ ...headerStyles, flexDirection: isMobileView ? 'row-reverse' : 'row' }}>
        <Flex align={'center'}>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/images/logo.svg"
              alt="logo"
              width="100%"
              onClick={onLogoClick}
              style={{ maxWidth: 50, display: 'block', cursor: 'pointer' }}
            />
          </Link>
        </Flex>
        {user &&
          isVerified &&
          (isMobileView ? (
            <img src={menuIcon} onClick={toggleDrawer} style={{ color: 'white', fontSize: '1.5em' }} alt="menu-icon" />
          ) : (
            <UserMenu />
          ))}
      </Header>
      {isMobileView && isVerified && <MobileUserMenu isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />}
      <Layout>
        <AppSidebar isMobileView={isMobileView} />
        <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
