import React from 'react';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

function ResponsiveVisibility({ visible, hidden, children }) {
  const screens = useBreakpoint();
  let isVisible = true;

  if (visible && !screens[visible]) {
    isVisible = false;
  }

  if (hidden && screens[hidden]) {
    isVisible = false;
  }

  if (!isVisible) return null;

  return <React.Fragment>{children}</React.Fragment>;
}

export default ResponsiveVisibility;
