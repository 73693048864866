import { Navigate } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import Loader from '../components/layouts/Loader'

export default function RequiredAdminRoute({ children }) {
  const { user, isLoading, isFetched } = useUser()

  if (isLoading || !isFetched) {
    return <Loader />
  }

  if (!user?.isAdmin) {
    return <Navigate to="/404" />
  }

  return children
}
