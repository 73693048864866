import OlympicsIcon from '../../assets/icons/olympics.svg'
import ExclusiveContentIcon from '../../assets/icons/exclusiveContent.svg'
import MyTeamsIcon from '../../assets/icons/myTeams.svg'
import HomeIcon from '../../assets/icons/home.svg'
import { ReadOutlined, SettingOutlined } from '@ant-design/icons'

export const iconsMap = {
  olympics: OlympicsIcon,
  myTeams: MyTeamsIcon,
  exclusiveContent: ExclusiveContentIcon,
  home: HomeIcon,
}

export const mobileMenu = [
  {
    src: 'home',
    text: 'בית',
    to: '/',
    title: 'הגדרות',
  },
  {
    src: 'myTeams',
    text: 'הקבוצות שלי',
    to: '/my-teams',
    title: 'הגדרות',
  },
  {
    src: 'exclusiveContent',
    text: 'תוכן אקסקלוסיבי',
    to: '/exclusive-content',
    title: 'הגדרות',
  },
  {
    icon: <ReadOutlined />,
    text: 'הרשמה לניוזלטר',
    to: '/newsletter/subscription',
    title: 'ניהול ניוזלטר',
  },
  {
    icon: <SettingOutlined />,
    text: 'הגדרות פרופיל',
    to: '/profile',
    title: 'הגדרות',
  },
  {
    icon: <ReadOutlined />,
    text: 'שינוי העדפות תוכן',
    to: '/preferred-sports',
    title: 'הגדרות',
  },
  {
    src: 'myTeams',
    text: 'הגדרת הקבוצות שלי',
    to: '/preferred-teams',
    title: 'הגדרות',
  },
]
