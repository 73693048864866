import { useState, useEffect } from 'react'
import { useResponsive } from './useResponsive'

/**
 * calculate the display mode of the teams logos, depending on the number of selected teams
 * @param {number} numberOfTeams
 * @returns display mode of the teams logos
 */
export function useTeamsResponsive(numberOfTeams) {
  const { isMobileView } = useResponsive()
  const [displayMode, setDisplayMode] = useState('desktop')

  useEffect(() => {
    const updateDisplayMode = () => {
      const tabList = document.querySelector(`[role="tablist"]`)
      if (!tabList) return
      const desktopView = window.innerWidth > 1150 && numberOfTeams <= 2
      const mobileView = window.innerWidth < 900 && numberOfTeams >= 2
      const tabletView = window.innerWidth < 1400

      const rect = tabList.getBoundingClientRect()
      const isLeakingLeft = rect.left < 0

      if (isLeakingLeft) {
        if (displayMode === 'desktop' && tabletView) setDisplayMode('tablet')
        else if (displayMode === 'tablet' && mobileView) setDisplayMode('mobile')
      } else {
        if (isMobileView || mobileView) {
          setDisplayMode('mobile')
          return
        }

        if (displayMode === 'mobile') setDisplayMode(tabletView && numberOfTeams >= 3 ? 'tablet' : 'desktop')
        else if (displayMode === 'tablet' && ((!tabletView && !mobileView) || desktopView)) setDisplayMode('desktop')
      }
    }
    updateDisplayMode()
    window.addEventListener('resize', updateDisplayMode)

    return () => {
      window.removeEventListener('resize', updateDisplayMode)
    }
  }, [displayMode, isMobileView, numberOfTeams])

  return {
    displayMode,
  }
}
