import { Table, Input, Row, Col } from 'antd'
import { debounce } from 'lodash'
import { useUsers } from '../../hooks/useUsers'
import dayjs from 'dayjs'

export default function Users() {
  const { response, setParams, isLoading } = useUsers()
  const { data = [], page, pageSize, total } = response

  const onPaginationChange = (page, pageSize) => {
    setParams({ page, pageSize })
  }

  const onSearchChange = debounce((event) => {
    const { value } = event.target
    setParams({ search: value })
  }, 500)

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      rowKey="_id"
      bordered
      pagination={{
        pageSize,
        total,
        current: page,
        onChange: onPaginationChange,
        defaultPageSize: 20,
      }}
      columns={[
        {
          title: 'כתובת דואר אלקטרוני',
          dataIndex: 'email',
        },
        {
          title: 'תאריך הרשמה',
          dataIndex: 'createdAt',
          render: (createdAt) => dayjs(createdAt).format('DD MMM, YYYY'),
        },
      ]}
      scroll={{ y: 'calc(100vh - 235px)' }}
      title={() => (
        <Row justify="space-between" align="center">
          <Col>
            <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
          </Col>
          <Col></Col>
        </Row>
      )}
    />
  )
}
