import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Typography, Row, Col, Flex } from 'antd'
import { useUser } from '../../hooks/useUser'
import ContentCard from '../../components/layouts/ContentCard'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterUnsubscription() {
  const { token } = useParams()
  const navigate = useNavigate()
  const { setToken, isLoading, error } = useUser()
  const { newsletter, isFetched } = useUserNewsletter()

  useEffect(() => {
    if (token) {
      setToken(token)
    }
  }, [token, setToken])

  useEffect(() => {
    if (isFetched && newsletter.status !== 'active') {
      navigate('/newsletter/unsubscription/complete')
    }
  }, [newsletter, isFetched, navigate])

  useEffect(() => {
    if (!isEmpty(error)) {
      navigate('/login')
    }
  }, [error, navigate])

  return (
    <Row justify="center" className="bg bg7">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard loading={isLoading}>
          <Row justify="center">
            <Col flex="600px">
              <Typography.Title level={3}>{'רוצה לקחת  TIME OUT מהעדכונים שלנו?'}</Typography.Title>
              <Flex vertical gap={64}>
                <Flex vertical gap={48}>
                  <Typography.Text>
                    {
                      'המאמנים הכי טובים לוקחים טיים אאוט כשצריך. אבל אם בא לך שנדייק את הכתבות שמגיעות אליך, תמיד אפשר לעדכן את ההעדפות. בנוסף, אפשר להפחית את התדירות.'
                    }
                  </Typography.Text>

                  <Flex justify="center">
                    <Flex align="start" gap={24} wrap justify="center">
                      <IconButton
                        label="שינוי העדפות"
                        src="/icons/newsletter-pref.svg"
                        to="/newsletter/preferences"
                        referrerMessage="כדי לשנות את העדפות התוכן, יש להזין את הקוד ששלחנו אליך עכשיו למייל"
                      />
                      <IconButton
                        label="שינוי תדירות"
                        src="/icons/newsletter-freq.svg"
                        to="/newsletter/frequency?multi=1"
                        referrerMessage="כדי לשנות את תדירות הניוזלטר, יש להזין את הקוד ששלחנו אליך עכשיו למייל"
                      />
                      <IconButton
                        label="שינוי העדפות & תדירות"
                        src="/icons/newsletter-pref-freq.svg"
                        to="/newsletter/preferences/?multi=1"
                        referrerMessage="כדי לשנות את העדפות התוכן ותדירות הניוזלטר, יש להזין את הקוד ששלחנו אליך עכשיו למייל"
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify="center">
                  <Link
                    to="/newsletter/unsubscription/options"
                    data-gtm-event_name="newsletter_unsubscribe"
                    data-gtm-description={'הסר אותי מרשימת התפוצה של הניוזלטר השוטף של ערוץ הספורט'}
                    data-gtm-flow="unsubscribe"
                    data-gtm-purpose="unsubscribe"
                    data-gtm-type="selection"
                  >
                    <Typography.Text underline type="secondary">
                      {'הסר אותי מרשימת התפוצה של הניוזלטר השוטף של ערוץ הספורט'}
                    </Typography.Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}

function IconButton({ src, label, to, referrerMessage }) {
  const navigate = useNavigate()

  const onClick = () => {
    sessionStorage.setItem('referrerMessage', referrerMessage)
    navigate(to)
  }

  return (
    <div
      className="nav-link"
      style={{ maxWidth: 101 }}
      onClick={onClick}
      data-gtm-event_name="element_click"
      data-gtm-description={label}
      data-gtm-flow="unsubscribe"
      data-gtm-purpose="preferences_selection"
      data-gtm-type="selection"
    >
      <Flex vertical align="center" gap={12}>
        <Flex justify="center" align="center">
          <img src={src} style={{ width: 100, maxWidth: '100%' }} alt="icon-btn" />
        </Flex>
        <span>{label}</span>
      </Flex>
    </div>
  )
}
