import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import axios from 'axios'
import { isEmpty } from 'lodash'

const endpoint = '/api/blogs'

export function useBlog(initialBlogsData = {}) {
  const [params, setParams] = useState(initialBlogsData)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!isEmpty(initialBlogsData)) {
      setParams(initialBlogsData)
    }
  }, [initialBlogsData, setParams])

  const fetchBlogs = async ({ pageParam = page }) => {
    const response = await axios.get(endpoint, {
      params: { ...params, page: pageParam },
    })
    return response.data
  }

  const { data, error, fetchNextPage, hasNextPage, isLoading, isError } = useInfiniteQuery(
    [endpoint, params, page],
    fetchBlogs,
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalItemsFetched = allPages.flatMap((page) => page.blogs).length
        const totalPages = Math.ceil(lastPage.total / params.pageSize)
        const nextPage = allPages.length + 1

        if (process.env.NODE_ENV === 'development') {
          console.log(`Total Items Fetched: ${totalItemsFetched}, Total Pages: ${totalPages}, Next Page: ${nextPage}`)
        }

        if (nextPage <= totalPages && totalItemsFetched < lastPage.total) {
          return nextPage
        }
        return undefined
      },
    },
  )

  return {
    isLoading,
    isError,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    setParams,
    setPage,
    page,
  }
}
