import { Button, Flex, Form, Modal, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { SelectFavoriteTeams } from '../../teams/components/SelectFavoriteTeams'
import { useTeams } from '../../../hooks/useTeams'
import { useUserPreferredTeams } from '../../../hooks/useUserPreferredTeams'
import { createAllTeamOptions } from '../../teams/MyTeams.utils'

export const FavoriteTeamPopup = ({ open, setOpen, handleClose, updateProfile }) => {
  const [form] = Form.useForm()
  const [isConfirmLoading, setIsConfirmLoading] = useState(false)
  const { refetch } = useUserPreferredTeams()
  const { data, isLoading } = useTeams()

  const sportCategories = useMemo(
    () =>
      data.reduce(
        (acc, team) => {
          return createAllTeamOptions(acc, team)
        },
        {
          israeliFootball: [],
          worldFootball: [],
          basketball: [],
          nba: [],
        },
      ),
    [data],
  )

  const onSubmit = async () => {
    setIsConfirmLoading(true)
    const data = Object.values(form.getFieldsValue(true)).filter((id) => id)
    await updateProfile({ disablePopup: true, preferredTeams: data })
    refetch()
    setIsConfirmLoading(false)
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      centered
      styles={{
        content: {
          backgroundImage: `url(/images/backgrounds/bg4-popup.png)`,
          backgroundSize: 'cover',
        },
      }}
      width={700}
      footer={
        <Flex justify="center">
          <Button shape="round" onClick={onSubmit} loading={isConfirmLoading} type="primary" htmlType="submit">
            המשך
          </Button>
        </Flex>
      }
      loading={isLoading}
      onCancel={handleClose}
    >
      <Flex justify="center" align="center">
        <Form layout="vertical" style={{ width: '391px' }} form={form}>
          <Typography.Title level={4} style={{ marginBottom: 0, textAlign: 'center' }}>
            <span>{'מהיום בוחרים את הקבוצות שלכם '}</span>
          </Typography.Title>
          <Typography.Title level={4} style={{ marginBottom: 0, textAlign: 'center' }}>
            <span>{'ומקבלים פיד שמותאם לכם'}</span>
          </Typography.Title>
          <SelectFavoriteTeams sportCategoriesOptions={sportCategories} />
        </Form>
      </Flex>
    </Modal>
  )
}
