import { Row, Col, Typography, Avatar, Flex, Button } from 'antd'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterUnsubscriptionComplete() {
  const onClick = () => {
    window.location.href = 'https://sport5.co.il'
  }

  return (
    <Row justify="center" className="bg bg3">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row justify="center">
            <Col flex="600px">
              <Flex vertical gap={24} align="center">
                <Avatar size={120} shape="square" src="/icons/unsubscribed.svg" />
                <div>
                  <Typography.Title level={1} style={{ marginBottom: 12 }}>
                    {'תודה לך'}
                  </Typography.Title>
                  <Typography.Title style={{ fontWeight: 400 }} level={4}>
                    {'הוסרת בהצלחה מרשימת התפוצה של הניוזלטר השוטף של ערוץ הספורט'}
                  </Typography.Title>
                </div>
                <Button
                  onClick={onClick}
                  type="primary"
                  style={{ marginBottom: 24 }}
                  shape="round"
                  data-gtm-event_name="outbound_click"
                  data-gtm-description="sport5"
                  data-gtm-flow="unsubscribe"
                  data-gtm-purpose="return_to_website"
                >
                  {'חזרה לאתר ערוץ הספורט'}
                </Button>
              </Flex>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
