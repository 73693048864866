import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { Layout, Flex, Affix } from 'antd'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { useUser } from '../../hooks/useUser'
import Loader from './Loader'

const { Header, Content } = Layout

export default function ProtectedPublicLayout() {
  const { user, isVerified, isLoading, isFetching } = useUser()

  if (isLoading || isFetching) {
    return <Loader />
  }

  if (user && isVerified) return <Navigate to="/" />

  return (
    <Layout>
      <Affix offsetTop={0}>
        <Header style={{ boxShadow: '0 -10px 20px 5px rgba(0,0,0,0.1)' }}>
          <Flex justify="center" align="center" style={{ height: '100%' }}>
            <a href={'https://www.sport5.co.il'} style={{ display: 'flex' }}>
              <Logo style={{ width: 45 }} />
            </a>
          </Flex>
        </Header>
      </Affix>
      <Layout>
        <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
