import { isEmpty } from 'lodash'
import { basketballKey, israeliTeamKey, nbaKey, worldTeamKey } from '../../utils/MyTeams.const'

export const createTeamOptionsForRegister = (acc, team, preferredSports) => {
  const teamCategory = team.sportCategories
  if (!isEmpty(preferredSports)) {
    const optionsData = { value: team._id, label: team.name, iconUrl: team.iconUrl }

    const validateSelectedPrefSports = (key, footballKey) => {
      if (preferredSports.some((prefSport) => prefSport.includes(key))) acc[footballKey].push(optionsData)
    }

    if (teamCategory[0].name === israeliTeamKey) {
      validateSelectedPrefSports(israeliTeamKey, 'israeliFootball')
    }
    if (teamCategory[0].name === worldTeamKey) {
      validateSelectedPrefSports(worldTeamKey, 'worldFootball')
    }
    if (teamCategory[0].name === basketballKey) {
      validateSelectedPrefSports(basketballKey, 'basketball')
    }
    if (teamCategory[0].name === nbaKey) {
      validateSelectedPrefSports(nbaKey, 'nba')
    }
  }

  return acc
}

export const createAllTeamOptions = (acc, team) => {
  const teamCategory = team.sportCategories
  const optionsData = { value: team._id, label: team.name, iconUrl: team.iconUrl }

  if (teamCategory[0].name === israeliTeamKey) {
    acc.israeliFootball.push(optionsData)
  }
  if (teamCategory[0].name === worldTeamKey) {
    acc.worldFootball.push(optionsData)
  }
  if (teamCategory[0].name === basketballKey) {
    acc.basketball.push(optionsData)
  }
  if (teamCategory[0].name === nbaKey) {
    acc.nba.push(optionsData)
  }

  return acc
}
