import { useUser } from '../hooks/useUser'
import Loader from '../components/layouts/Loader'
import { Navigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useGetUserPreferredSports } from '../hooks/usePreferredSports'

export default function RequiredRegistrationRoute({ children }) {
  const { user, isLoading } = useUser()
  const {
    data: preferredSports,
    isLoading: isPreferredSportsLoading,
    isFetching: isPreferredSportsFetching,
  } = useGetUserPreferredSports()
  const { firstName } = user

  if (isLoading || isPreferredSportsLoading || isPreferredSportsFetching) {
    return <Loader />
  }

  if (!firstName) {
    return <Navigate to="/register/profile" />
  }

  if (isEmpty(preferredSports)) {
    return <Navigate to="/register/preferred-sports" />
  }

  return children
}
