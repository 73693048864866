import { useState } from 'react'
import { Row, Col, Typography, Input, Button, Form, message, Flex, Checkbox, Modal } from 'antd'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { validateEmail } from '../../utils/validators'
import { PizzaHutNewsletterWrapper } from './components/PizzaHutNewsletterWrapper'
import { useResponsive } from '../../hooks/useResponsive'

export default function PizzaHutNewsletterRegistration() {
  const [isLoading, setIsLoading] = useState(false)
  const { login } = useUser()
  const navigate = useNavigate()
  const { isMobileView } = useResponsive()
  const [isPolicyAgreed, setIsPolicyAgreed] = useState(false)

  const onSubmit = async (values) => {
    if (!isPolicyAgreed) {
      return Modal.info({
        className: 'centered-content-modal',
        centered: true,
        title: 'יש לאשר את התנאים כדי להמשיך בהרשמה',
        icon: null,
      })
    }

    try {
      setIsLoading(true)
      await login(values, 'newsletter')

      navigate({
        pathname: '/login/verification',
        search: createSearchParams({
          flow: 'newsletter',
          redirect: '/newsletter/register/profile',
        }).toString(),
      })
    } catch (error) {
      const { response = {} } = error
      const { data } = response
      message.error(data || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PizzaHutNewsletterWrapper>
      <Row justify="center" gutter={[0, 20]}>
        <Col xs={24} flex="750px">
          <Flex justify="center" vertical>
            <Typography.Title level={1} style={{ color: 'white', marginTop: isMobileView ? 90 : 140 }}>
              {`הירשמו לניוזלטר של ערוץ הספורט והיכנסו לפעילות!`}
            </Typography.Title>
            <Typography.Text style={{ color: 'white', margin: 'auto', fontSize: isMobileView ? '20px' : '22px' }}>
              {`קבלו כתבות מותאמות אישית, וחידה יומית`}
            </Typography.Text>
            <Typography.Text style={{ color: 'white', margin: 'auto', fontSize: isMobileView ? '20px' : '22px' }}>
              {`כל יום עשרת הפותרים הראשונים יזכו במגש פיצה מתנת פיצה האט!`}
            </Typography.Text>
          </Flex>
        </Col>
        <Col xs={24} flex="500px">
          <Flex vertical gap={16}>
            <Flex vertical>
              <Typography.Title level={2} style={{ color: 'white', margin: 0 }}>
                {`אז מה האימייל שלך?`}
              </Typography.Title>
            </Flex>
            <Form onFinish={onSubmit} validateTrigger="onSubmit">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={17}>
                  <Flex vertical>
                    <Form.Item
                      name="email"
                      style={{ marginBottom: '16px' }}
                      rules={[
                        {
                          required: true,
                          message: <div style={{ color: 'white' }}>{'יש להזין דוא״ל'}</div>,
                          validator: (_, email) => {
                            if (!validateEmail(email)) {
                              return Promise.reject('הכתובת אינה תקינה')
                            }

                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Input
                        className="placeholder-rtl"
                        style={{ borderColor: 'white', direction: 'ltr' }}
                        placeholder="דוא׳׳ל שלי"
                      />
                    </Form.Item>
                    <Typography.Text style={{ color: 'white', fontSize: '16px', alignSelf: 'start' }}>
                      {'*לשם יישלח קוד האימות'}
                    </Typography.Text>
                    <Typography.Text style={{ color: 'white', fontSize: '16px', alignSelf: 'start' }}>
                      <a
                        href="https://www.sport5.co.il/articles.aspx?FolderID=413&docID=487511"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white', textDecoration: 'underline' }}
                      >
                        {'**בכפוף לתקנון הפעילות'}
                      </a>
                    </Typography.Text>
                  </Flex>
                </Col>
                <Col xs={24} md={7}>
                  <Button
                    loading={isLoading}
                    htmlType="submit"
                    block
                    shape="round"
                    style={{ background: 'white', color: '#E71316', borderColor: '#E71316' }}
                    type="primary"
                  >
                    {'כניסה'}
                  </Button>
                </Col>
                <Col xs={24}>
                  <Flex gap={12} align="flex-start">
                    <Checkbox
                      className="checkbox-white"
                      defaultChecked={isPolicyAgreed}
                      onChange={(e) => setIsPolicyAgreed(e.target.checked)}
                    />
                    <Typography.Text style={{ color: 'white', fontSize: 12, textAlign: 'right' }}>
                      {
                        'אני מאשר קבלת חומרים שיווקיים, עדכונים ומידע, של ערוץ הספורט ושל שותפיו העסקיים, באמצעות שיחות, מסרונים, דוא"ל או כל תקשורת דיגיטלית אחרת. תוכל בכל עת לבטל הסכמתך באמצעות פנייה בדוא"ל ל '
                      }
                      <a style={{ color: 'white', textDecoration: 'underline' }} href="mailto:support@rge.co.il">
                        {'support@rge.co.il'}
                      </a>
                      {` או באמצעות דרכי ההסרה שיפורטו בהודעה. `}
                    </Typography.Text>
                  </Flex>
                </Col>
              </Row>
            </Form>
          </Flex>
        </Col>
      </Row>
    </PizzaHutNewsletterWrapper>
  )
}
