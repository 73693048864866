import { useState } from 'react'
import { message } from 'antd'
import SportCategoriesForm from '../../../components/forms/SportCategoriesForm'
import { useUserNewsletter } from '../../../hooks/useUserNewsletter'
import { useNavigate } from 'react-router-dom'
import { useUpdateUserPreferredSports } from '../../../hooks/usePreferredSports'
import { track } from '../../../utils/analytics'

export default function NewsletterCategoriesForm({ submitText, gaFlow, postSubmitRedirectUrl }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { updateNewsletterSubscriptions } = useUserNewsletter()
  const { updatePreferredSports} = useUpdateUserPreferredSports()

  
  const onSubmit = async (checkedNodes) => {
    try {
      setIsLoading(true)
      const checkedKeys = checkedNodes.map((node) => node.key)

      await Promise.all([
        updatePreferredSports(checkedKeys),
        updateNewsletterSubscriptions({ checkedNodes })
      ])

      track({
        event_name: 'form_submit',
        form_name: 'interests_selection',
        flow: 'newsletter',
        purpose: 'interests',
      })

      if (postSubmitRedirectUrl) {
        navigate(postSubmitRedirectUrl)
      }


    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SportCategoriesForm
      isLoading={isLoading}
      submitText={submitText}
      onSubmit={onSubmit}
      gaFlow={gaFlow}
    />
  )
}
