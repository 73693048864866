import { Table, Input, Row, Col } from 'antd'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { useLogs } from '../../hooks/useLogs'

export default function Logs() {
  const { response, setParams, isLoading } = useLogs()
  const { data = [], page, pageSize, total } = response

  const onPaginationChange = (page, pageSize) => {
    setParams({ page, pageSize })
  }

  const onSearchChange = debounce((event) => {
    const { value } = event.target
    setParams({ search: value })
  }, 500)

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      rowKey="_id"
      bordered
      pagination={{
        pageSize,
        total,
        current: page,
        onChange: onPaginationChange,
        defaultPageSize: 20,
      }}
      columns={[
        {
          title: 'level',
          dataIndex: 'level',
          width: 120,
        },
        {
          title: 'message',
          dataIndex: 'message',
        },

        {
          align: 'left',
          dataIndex: 'createdAt',
          render: (createdAt) => dayjs(createdAt).format('DD/MM/YY HH:mm'),
        },
      ]}
      scroll={{ y: 'calc(100vh - 235px)' }}
      title={() => (
        <Row justify="space-between" align="center">
          <Col>
            <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
          </Col>
          <Col></Col>
        </Row>
      )}
    />
  )
}
