import { Divider, Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import Sider from 'antd/es/layout/Sider'
import { styles, renderIcons } from './AppSidebar.utils'
import TeamsIcons from '../elements/TeamsIcons'
import { useUserPreferredTeams } from '../../hooks/useUserPreferredTeams'
import { Fragment } from 'react'

export default function AppSidebar({ isMobileView }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { data } = useUserPreferredTeams()
  const onClick = ({ key }) => {
    navigate(key)
  }

  if (isMobileView) return null

  const menu = [
    {
      key: '/',
      icon: 'home',
      label: 'בית',
    },
    {
      key: '/my-teams',
      icon: 'myTeams',
      label: 'הקבוצות שלי',
    },
    {
      key: '/exclusive-content',
      icon: 'exclusiveContent',
      label: 'תוכן אקסקלוסיבי',
    },
  ]
  return (
    <Sider
      style={{
        borderLeft: '1px solid #F5F5F5',
        position: 'sticky',
        height: 'calc(100vh - 64px)',
        top: '64px',
      }}
      width={300}
    >
      <Menu onClick={onClick} style={styles.menu} selectedKeys={[location.pathname]} mode="inline">
        <TeamsIcons teamsData={data} isMobileView={isMobileView} />
        {menu.map((page) => {
          if (page.key === '/')
            return (
              <Menu.Item key={page.key} style={styles.menuItem} icon={renderIcons(page.icon)}>
                {page.label}
              </Menu.Item>
            )
          return (
            <Fragment key={page.key}>
              <Divider style={{ width: '260px', margin: 'unset', minWidth: 'unset', marginRight: '30px' }} />
              <Menu.Item
                data-gtm-event_name="element_click"
                data-gtm-location="side_menu"
                data-gtm-level1={page.label}
                data-gtm-type="navigation"
                key={page.key}
                style={styles.menuItem}
                icon={renderIcons(page.icon)}
              >
                {page.label}
              </Menu.Item>
            </Fragment>
          )
        })}
      </Menu>
    </Sider>
  )
}
