import { Helmet } from 'react-helmet'

export const HelmetHeader = ({ title = 'ספורט 5', description = 'אזור אישי - ערוץ הספורט' }) => {
  const canonicalUrl = window.location.href
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  )
}
