import { useEffect } from 'react'
import { Row, Col, Typography, Avatar, Flex, Button } from 'antd'
import BackButton from '../../components/elements/BackButton'
import ContentCard from '../../components/layouts/ContentCard'
import { useUser } from '../../hooks/useUser'
import { isEmpty } from 'lodash'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterRegistrationComplete() {
  const { logOut, user } = useUser()
  useEffect(() => {
    if (!isEmpty(user)) {
      logOut()
    }
  }, [user, logOut])

  return (
    <Row justify="center" className="bg bg3">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row justify="center">
            <Col flex="600px">
              <Flex vertical gap={24} align="center">
                <Avatar size={120} shape="square" src="/icons/ok.svg" />
                <div>
                  <Typography.Title level={1} style={{ marginBottom: 12 }}>
                    {'קיבלנו'}
                  </Typography.Title>
                  <Typography.Title style={{ fontWeight: 400 }} level={4}>
                    {'העדפות התוכן שלך התעדכנו, מעכשיו ישלחו אליך תכנים בהתאם למה שבחרת.'}
                  </Typography.Title>
                </div>
                <a href="https://sport5.co.il">
                  <Button
                    type="primary"
                    style={{ marginBottom: 24 }}
                    shape="round"
                    data-gtm-event_name="outbound_click"
                    data-gtm-description="sport5"
                    data-gtm-flow="newletter"
                    data-gtm-purpose="return_to_website"
                  >
                    {'חזרה לאתר ערוץ הספורט'}
                  </Button>
                </a>
              </Flex>
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
