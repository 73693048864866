export const track = ({ event_name, ...rest }) => {
  if (!event_name) return

  const payload = {
    event_name,
    event: 'fireEvent',
    ...rest,
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('dataLayer.push', payload)
  }

  window?.dataLayer?.push(payload)
}
