import { useQuery } from 'react-query'
import axios from 'axios'

const endpoint = '/api/teams'

export function useTeams() {
  const {
    isLoading,
    data: response = {},
    refetch,
  } = useQuery({
    queryKey: [endpoint],
    queryFn: () => axios.get(endpoint),
  })

  const createTeam = async (values) => {
    await axios.post(endpoint, values)
    refetch()
  }

  const deleteTeam = async (id) => {
    await axios.delete(`${endpoint}/${id}`)
    refetch()
  }

  const updateTeam = async (values) => {
    await axios.put(`${endpoint}/${values._id}`, values)
    refetch()
  }

  const { data = [] } = response

  return {
    isLoading,
    data,
    createTeam,
    deleteTeam,
    updateTeam,
  }
}
