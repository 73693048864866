import React from 'react'
import ResponsiveVisibility from '../layouts/ResponsiveVisibility'
import { DesktopArticle, ExclusiveArticle, ExclusiveMobileArticle } from './ArticleComponents'

const Article = ({ imageUrl, date, title, description, authorName, url, teamName, exclusiveContent, useExclusive }) => (
  <>
    <ResponsiveVisibility visible="lg">
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        {useExclusive ? (
          <ExclusiveArticle
            imageUrl={imageUrl}
            date={date}
            title={title}
            description={description}
            authorName={authorName}
          />
        ) : (
          <DesktopArticle
            imageUrl={imageUrl}
            date={date}
            title={title}
            description={description}
            authorName={authorName}
            teamName={teamName}
            exclusiveContent={exclusiveContent}
          />
        )}
      </a>
    </ResponsiveVisibility>
    <ResponsiveVisibility hidden="lg">
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <ExclusiveMobileArticle
          imageUrl={imageUrl}
          date={date}
          title={title}
          description={description}
          authorName={authorName}
          teamName={teamName}
          exclusiveContent={exclusiveContent}
        />
      </a>
    </ResponsiveVisibility>
  </>
)

export default Article
