import { Flex, Typography } from 'antd'

export default function Loader({ text = '' }) {
  return (
    <Flex justify="center" align="center" style={{ minHeight: '100vh' }}>
        <Flex justify="center" align="center" style={{ flexDirection: 'column', gap: '5px' }}>
          {text && <Typography.Text>{text}</Typography.Text>}
          <img src={'/images/logo.svg'} alt="logo" width="100%" style={{ maxWidth: 100, display: 'block' }} />
        </Flex>
    </Flex>
  )
}
