import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import Loader from '../components/layouts/Loader'

export default function RequiredVerificationRoute({ children }) {
  const { user, isLoading } = useUser()
  const location = useLocation()

  if (isLoading) {
    return <Loader />
  }

  if (!user.isVerified) {
    return <Navigate to={`/login?redirect=${location.pathname}`} state={{ user }} />
  }

  return children
}
