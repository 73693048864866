import React, { useMemo, useState } from 'react'
import { Avatar, Menu, Dropdown, Divider, Flex, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { useUser } from '../../hooks/useUser'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'

const UserMenu = () => {
  const navigate = useNavigate()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const { user, logOut } = useUser()
  const { newsletter } = useUserNewsletter()

  const username = useMemo(() => {
    if (!user) return
    const { email, firstName } = user
    if (firstName) return firstName

    const [username] = email.split('@')
    return username
  }, [user])

  const handleLogout = () => {
    logOut()
  }

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      handleLogout()
    } else {
      const item = navigationItems.find((item) => item.key === e.key)
      if (item) navigate(item.path)
    }
  }

  const navigationItems = [
    { key: 'newsletter', label: 'ניהול ניוזלטר', path: '' },
    { key: 'preferred-sports', label: 'שינוי העדפת תוכן', path: '/preferred-sports' },
    { key: 'preferred-teams', label: 'שינוי העדפת תוכן', path: '/preferred-teams' },
    { key: 'frequency', label: 'ניהול תדירות', path: '/newsletter/frequency' },
    { key: 'profile', label: 'הגדרות פרופיל', path: '/profile' },
    { key: 'newsletter-subscription', path: '/newsletter/subscription' },
  ]

  return (
    <Flex align={'center'}>
      <Dropdown
        open={isDropdownVisible}
        overlay={
          <Menu
            style={{ width: '200px', backgroundColor: '#F0F6FD', borderRadius: '4px 0px 0px 7px' }}
            onClick={handleMenuClick}
          >
            <Menu.ItemGroup
              title={
                <Typography.Text strong>
                  <span style={{ height: '90%' }}>|</span> ניהול ניוזלטר
                </Typography.Text>
              }
            >
              {newsletter?.status !== 'active' ? (
                <Menu.Item
                  key="newsletter-subscription"
                  data-gtm-event_name="element_click"
                  data-gtm-location="main_menu"
                  data-gtm-level1={'ניהול ניוזלטר'}
                  data-gtm-level2={'הרשמה'}
                  data-gtm-type="navigation"
                  data-gtm-flow="main"
                >
                  {'הרשמה'}
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="frequency"
                  data-gtm-event_name="element_click"
                  data-gtm-location="main_menu"
                  data-gtm-level1={'ניהול ניוזלטר'}
                  data-gtm-level2={'ניהול תדירות'}
                  data-gtm-type="navigation"
                  data-gtm-flow="main"
                >
                  {'ניהול תדירות'}
                </Menu.Item>
              )}
            </Menu.ItemGroup>
            <Divider />
            <Menu.ItemGroup
              title={
                <Typography.Text strong>
                  <span style={{ height: '90%' }}>|</span> הגדרות
                </Typography.Text>
              }
            >
              <Menu.Item
                key="profile"
                data-gtm-event_name="element_click"
                data-gtm-location="main_menu"
                data-gtm-level1={'הגדרות'}
                data-gtm-level2={'פרופיל'}
                data-gtm-type="navigation"
                data-gtm-flow="main"
              >
                {'פרופיל'}
              </Menu.Item>
              <Menu.Item
                key="preferred-sports"
                data-gtm-event_name="element_click"
                data-gtm-location="main_menu"
                data-gtm-level1={'הגדרות'}
                data-gtm-level2={'העדפות תוכן'}
                data-gtm-type="navigation"
                data-gtm-flow="main"
              >
                {'העדפות תוכן'}
              </Menu.Item>
              <Menu.Item
                key="preferred-teams"
                data-gtm-event_name="element_click"
                data-gtm-location="main_menu"
                data-gtm-level1={'הגדרות'}
                data-gtm-level2={'הגדרת הקבוצות שלי'}
                data-gtm-type="navigation"
                data-gtm-flow="main"
              >
                {'הגדרת הקבוצות שלי'}
              </Menu.Item>
            </Menu.ItemGroup>
            <Divider />
            <Menu.Item key="logout">יציאה</Menu.Item>
          </Menu>
        }
        onOpenChange={(visible) => setIsDropdownVisible(visible)}
        trigger={['click']}
      >
        <div style={{ cursor: 'pointer' }}>
          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
          <Typography.Text
            strong={true}
            underline={true}
            style={{
              margin: '0 8px',
              color: '#B5D7FF',
              fontSize: '20px',
              textDecorationThickness: '10%',
            }}
          >
            {`היי ${username}`}
          </Typography.Text>
          <DownOutlined
            style={{ color: '#FFFFFF', transform: isDropdownVisible ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </div>
      </Dropdown>
    </Flex>
  )
}

export default UserMenu
